/*====================================
	Gallery
=======================================*/
.detail-page-title{
    float: left;
    width: 100%;
    padding: 0 0 27px;
     h4{
        color: $dark-txt;
        padding-bottom: 3px;
    }
    .subtitle{
        float: left;
        width: 100%;
        font-size: $size-menu;
        font-weight: $main-bold;
        color: $light-col;
        span{
            font-weight: $main-semibold;
            color: $light-col;
        }
    }
}

.detail-page-rate{
    float: right;
    padding: 0 0 27px;
    .detail-rate-text{
        float: left;
        padding: 0 20px 0 0;
        h6{
            font-size: $size-tab;
            color: $primary-col;
            text-align: right;
            padding: 6px 0 2px;
        }
        a{
            color: $secondary-col;
            font-size: $size-base;
            text-decoration: underline;
            &:hover{
                color: $primary-col;
            }
        }
    }
}

.detail-rate-button{
    float: left;
	
    .primary-button{
        font-size: $size-h5;
        font-weight: $main-semibold;
        height: auto;
        padding: 11px 10px 12px;
        text-transform: none;
        min-width: 166px;
        max-width: 166px;		
		cursor: default;
		
		&:hover{
			background: $primary-col;
		}
    }
}

.details-block{
    float: left;
    width: 100%;
    .book-button{
        float: left;
        width: 100%;
        .primary-button{
            float: left;
            width: 100%;
            font-size: $size-h2;
            height: auto;
            min-width: 0;
            max-width: none;
            padding: 25px 10px 27px;
        }
    }
    .details-single{
        float: left;
        width: 100%;
        margin: 10px 0 0;
        background: $border-col;
        padding: 12px 18px;
		
        .detail-list{
            float: left;
            width: 100%;
            li{
                list-style: none;
                float: left;
				display: table;
                width: 100%;
                border-top: 1px solid $list-border;
                padding: 8px 0;
                &:first-child{
                    border-top: none;
                }
                label{
                    display: table-cell;
					vertical-align: middle;
					width: 35%;
                    color: $col-para;
                    font-size: $size-base;
                }
                span{
                    display: table-cell;
					vertical-align: middle;
					width: 45%;
                    color: $tab-content;
                    font-size: $size-menu;
                    font-weight: $main-semibold;
                    text-align: right;
                    sup{
                        font-size: $size-extra-small;
                        color: $tab-content;
                        font-weight: $main-semibold;
                    }
                }
            }
        }
        .detail-share{
            float: left;
            padding: 8px 11px 9px;
            li{
                float: left;
                list-style: none;
                margin-right: 5px;
                &.facebook{
                    a{
                        background: $facebook-bg;
                    }
                }
                &.twitter{
                    a{
                        background: $twitter-bg;
                    }
                }
                &.linkedin{
                    a{
                        background: $linkedin-bg;
                    }
                }
                &.plus{
                    a{
                        background: $plus-bg;
                    }
                }
                a{
                    float: left;
                    height: 32px;
                    width: 32px;
                    line-height: 32px;
                    text-align: center;
                    i{
                        font-size: 25px;
                        line-height: 32px;
                        color: $lighter;
                    }
                    &:hover{
                        opacity: .8;
                    }
                }
            }
        }
        .detail-download{
            float: right;
            padding: 5px 0px 9px;
            a{
                span{
                    float: right;
                    font-size: $size-base;
                    color: $download-color;
                    padding-left: 5px;
                    line-height: 40px;
                    @include transit;
                }
                img{
                    width: 36px;
                    height: 38px;
                }
                &:hover{
                    span{
                        color: $primary-color;
                    }
                }
            }
        }
    }
}


.detail-page-slider{
	float: left;
	width: 100%;
}
.detail-outer{
	float: left;
	width:100%;
	padding: 48px 0 0;
}
.detail-slider {
    float: left;
    width: 100%;
    position: relative;
    
    .primary-button{
        position: absolute;
        top: 10px;
        left: 0;
		cursor: default;
		
		&:hover{
			background: $primary-col;
		}
    }
    .galleria-theme-classic {
        background: none !important;
        width: 100%;
		overflow: visible;
        .galleria-stage {
            top: 0;
            width: 100%;
        }
        .galleria-info {
            display: none;
            opacity: 0;
        }
        .galleria-thumbnails-container {
            width: 100%;
            height: 91px;
            right: 0;
            bottom: -104px;
            left: 0;
            margin: 0 auto;
            .galleria-thumbnails{
                //width: 100%!important; Commented cause thumbnails show white space
                margin: 0 auto;
            }
        }
        .galleria-thumbnails .galleria-image {
            border: 0 none;
            height: 91px;
            width: 123px !important;
            margin: 0 5px;
        }
        .galleria-image-nav-left {
            left: 0 !important;
            background: $darker;
            opacity: .5;
            top: 50%;
            @include transform-translatey(-50%);
        }
        .galleria-image-nav-right {
            right: 0 !important;
            background: $darker;
            opacity: .5;
            top: 50%;
            @include transform-translatey(-50%);
        }
    }
}
.detail-page-slider {
    float: left;
    width: 100%;
    padding: 0 0 20px;
    .galleria {
        width: 100%;
        height: 454px;
    }
    .gallery-details {
        width: 34.1%;
        background: $lighter;
        position: absolute;
        right: 0;
        top: 0;
		display: table;
		.detail-inner{
			display: table-cell;
			height: 100%;
			position: relative;
            .detail-head{
                width: 100%;
                float: left;
                padding: 55px 30px 10px;
                text-align: center;
            }
        }
    }
    .expand {
        position: absolute;
        right: 0;
        top: 0px;
        z-index: 100;
    }
}


.galleria {
    float: left;
}

.galleria-images {
    width: 100% !important;
    float: left;
}

.galleria-image {
    width: 100% !important;
    float: left;
}

.galleria-theme-classic .galleria-image-nav-left, .galleria-theme-classic .galleria-image-nav-right {
    background-image: none;
    border: 0 none;
    border-radius: 0;
    height: 60px;
    opacity: 1;
    text-align: center;
    transition: none 0s ease 0s !important;
    width: 42px;
}
.galleria-theme-classic .galleria-image-nav-left:before, .galleria-theme-classic .galleria-image-nav-right:before {
    color: $lighter;
    content: "";
    font-family: "FontAwesome";
    font-size: $size-h5;
    height: 60px;
    left: 0;
    line-height: 60px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 42px;
}
.galleria-theme-classic .galleria-image-nav-left:before {
    content: "\f104";
}
.galleria-theme-classic .galleria-image-nav-right:before {
    content: "\f105";
}
.galleria-theme-classic img {
    opacity: 1 !important;
    top: 0 !important;
    // width: 100% !important;
}
.galleria-theme-classic .galleria-thumbnails .galleria-image.active {
    opacity: 0.7 !important;
}
.galleria-theme-classic .galleria-carousel .galleria-thumbnails-list{
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
}
.galleria-theme-classic .galleria-thumb-nav-left:before, .galleria-theme-classic .galleria-thumb-nav-right:before {
    background: $button-slider;
    color: $lighter;
    content: "";
    font-family: "FontAwesome";
    font-size: $size-h5;
    height: 34px;
    line-height: 31px;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 34px;
    z-index: 9;
    right: 0;
    @include transform-translatey(-50%);
}
.galleria-theme-classic .galleria-thumb-nav-left:before {
    content: "\f104";
    left: 0;
}
.galleria-theme-classic .galleria-thumb-nav-right:before {
    content: "\f105";
}
.galleria-theme-classic .galleria-thumbnails-container .disabled {
    opacity: 1;
}
.galleria-theme-classic .galleria-thumb-nav-left, .galleria-theme-classic .galleria-thumb-nav-right {
    background: none !important;
    cursor: pointer;
    height: 34px;
    top: 30px;
    width: 34px;
}
.galleria-theme-classic .galleria-thumb-nav-left {
    left: 0;
}
.galleria-theme-classic .galleria-thumb-nav-right {
    left: auto;
    right: 0;
}
.galleria-theme-classic.notouch .galleria-thumb-nav-left:hover, .galleria-theme-classic.notouch .galleria-thumb-nav-right:hover {
   background: none !important;
}
.detail-slider .galleria-theme-classic.fullscreen .galleria-stage{
    width: 100%;
}
.detail-slider .galleria-theme-classic.fullscreen .galleria-info{
    display: none !important;
}
.detail-slider .galleria-theme-classic.fullscreen .galleria-thumbnails-container {
    bottom: 0;
}



#galleria-loader{height:1px!important}

.galleria-theme-classic {
    position: relative;
    overflow: hidden;
}
.galleria-theme-classic img {
    -moz-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
	float: left;
}
.galleria-theme-classic .galleria-stage {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow:hidden;
}
.galleria-theme-classic .galleria-thumbnails-container {
    height: 50px;
    bottom: 0;
    position: absolute;
    left: 10px;
    right: 10px;
    z-index: 2;
}
.galleria-theme-classic .galleria-carousel .galleria-thumbnails-list {
    margin-left: 52px;
    margin-right: 52px;
}
.galleria-theme-classic .galleria-thumbnails .galleria-image {
    height: 40px;
    width: 60px;
    margin: 0 5px 0 0;
    float: left;
    cursor: pointer;
}
.galleria-theme-classic .galleria-counter {
    position: absolute;
    bottom: 10px;
    left: 10px;
    text-align: right;
    color: #fff;
    font: normal 11px/1 arial,sans-serif;
    z-index: 1;
}
.galleria-theme-classic .galleria-loader {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
    display: none;
}
.galleria-theme-classic .galleria-info {
    width: 50%;
    top: 15px;
    left: 15px;
    z-index: 2;
    position: absolute;
}
.galleria-theme-classic .galleria-info-text {
    background-color: #000;
    padding: 12px;
    display: none;
    /* IE7 */ zoom:1;
}
.galleria-theme-classic .galleria-info-title {
    font: bold 12px/1.1 arial,sans-serif;
    margin: 0;
    color: #fff;
    margin-bottom: 7px;
}
.galleria-theme-classic .galleria-info-description {
    font: italic 12px/1.4 georgia,serif;
    margin: 0;
    color: #bbb;
}
.galleria-theme-classic .galleria-info-close {
    width: 9px;
    height: 9px;
    position: absolute;
    top: 5px;
    right: 5px;
    background-position: -753px -11px;
    opacity: .5;
    filter: alpha(opacity=50);
    cursor: pointer;
    display: none;
}
.galleria-theme-classic .notouch .galleria-info-close:hover{
    opacity:1;
    filter: alpha(opacity=100);
}
.galleria-theme-classic .touch .galleria-info-close:active{
    opacity:1;
    filter: alpha(opacity=100);
}
.galleria-theme-classic .galleria-info-link {
    background-position: -669px -5px;
    opacity: .7;
    filter: alpha(opacity=70);
    position: absolute;
    width: 20px;
    height: 20px;
    cursor: pointer;
    background-color: #000;
}
.galleria-theme-classic.notouch .galleria-info-link:hover {
    opacity: 1;
    filter: alpha(opacity=100);
}
.galleria-theme-classic.touch .galleria-info-link:active {
    opacity: 1;
    filter: alpha(opacity=100);
}
.galleria-theme-classic .galleria-image-nav {
    position: absolute;
    top: 50%;
    margin-top: -30px;
    width: 100%;
    height: 62px;
    left: 0;
}
.galleria-theme-classic .galleria-image-nav-left,
.galleria-theme-classic .galleria-image-nav-right {
    opacity: .5;
    filter: alpha(opacity=30);
    cursor: pointer;
    width: 42px;
    height: 59px;
    position: absolute;
    left: 10px;
    z-index: 2;
    background-position: 0 46px;
}
.galleria-theme-classic .galleria-image-nav-right {
    left: auto;
    right: 10px;
    background-position: -254px 46px;
    z-index: 2;
}
.galleria-theme-classic.notouch .galleria-image-nav-left:hover,
.galleria-theme-classic.notouch .galleria-image-nav-right:hover {
    opacity: 1;
    background: $primary-col;
}
.galleria-theme-classic.touch .galleria-image-nav-left:active,
.galleria-theme-classic.touch .galleria-image-nav-right:active {
    opacity: 1;
    background: $primary-col;
}
.galleria-theme-classic .galleria-thumb-nav-left,
.galleria-theme-classic .galleria-thumb-nav-right {
    cursor: pointer;
    display: none;
    background-position: -495px 5px;
    position: absolute;
    left: 0;
    top: 0;
    height: 40px;
    width: 23px;
    z-index: 3;
    opacity: .8;
    filter: alpha(opacity=80);
    top: 50%;
    @include transform-translatey(-50%);
}
.galleria-theme-classic .galleria-thumb-nav-right {
    background-position: -578px 5px;
    border-right: none;
    right: 0;
    left: auto;
}
.galleria-theme-classic .galleria-thumbnails-container .disabled {
    opacity: .2;
    filter: alpha(opacity=20);
    cursor: default;
}
.galleria-theme-classic.notouch .galleria-thumb-nav-left:hover,
.galleria-theme-classic.notouch .galleria-thumb-nav-right:hover {
    opacity: 1;
    filter: alpha(opacity=100);
    background-color: #111;
}
.galleria-theme-classic.touch .galleria-thumb-nav-left:active,
.galleria-theme-classic.touch .galleria-thumb-nav-right:active {
    opacity: 1;
    filter: alpha(opacity=100);
    background-color: #111;
}
.galleria-theme-classic.notouch .galleria-thumbnails-container .disabled:hover {
    opacity: .2;
    filter: alpha(opacity=20);
    background-color: transparent;
}

.galleria-theme-classic .galleria-carousel .galleria-thumb-nav-left,
.galleria-theme-classic .galleria-carousel .galleria-thumb-nav-right {
    display: block;
}
.galleria-theme-classic .galleria-thumb-nav-left,
.galleria-theme-classic .galleria-thumb-nav-right,
.galleria-theme-classic .galleria-info-link,
.galleria-theme-classic .galleria-info-close,
.galleria-theme-classic .galleria-image-nav-left,
.galleria-theme-classic .galleria-image-nav-right {
    
    background-repeat: no-repeat;
}
.galleria-theme-classic.galleria-container.videoplay .galleria-info,
.galleria-theme-classic.galleria-container.videoplay .galleria-counter{ display:none!important; }

.galleria-theme-classic .galleria-thumb-nav-right::before {
    content: "\f105";
}


.galleria-exit{
	cursor: pointer;
    float: right;
    font-size: 0;
    height: 35px;
    position: absolute;
    right: 60px;
    top: 100px;
    width: 35px;
    z-index: 10;
	background: url(../images/close_btn.svg) no-repeat left top;
	background-size: 35px 35px;
}
.galleria-container.notouch.galleria-theme-classic.fullscreen{
	margin-top: 75px;
}

/*====================================
	Detail Banner
=======================================*/
.home-banner-slider.has-overlay{
    position: relative;
        &:before{
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: $darker;
        opacity: .4;
        z-index: 1;
    }
}
.home-banner.inner .search-content{
	 bottom: 48px;
}

.home-banner.inner .slick-track {
    height: 309px;
}

/*====================================
	Tab Content
=======================================*/
.detail-tab{
	float: left;
	width: 100%;
	margin-top: 50px;
	border-bottom: 2px solid $border-col3;
	
	.tabs{
		float: left;
		width: auto;
		background: none;		
		border: none;
		position: relative;
		
		li{
			margin-left: 5px;
			background: $border-col3;	
			
			a{
				background: $border-col3;	
				color: $tab-txt;
				font-weight: 600;
				font-size: $size-tab;
				padding: 17px 30px 19px 29px;
				float: left;
			}
			
			&.is-active.tabs-title{
				background: $primary-col;				
				
				a{
					background: $primary-col;	
					color: $lighter;
				}
			}
			
			&:first-child{
				margin-left: 0;
			}
		}
		
		&:after{
			position: absolute;
			left: 100%;
			bottom: 0;
			content: "";
			width: 109%;
			height: 2px;
			background: $border-col3;
		}
	}
	
	.tabs-content{
		float: left;
		width: 100%;
		border: none;
		padding-bottom: 50px;
		
		.tabs-panel{
			padding: 35px 0 20px;
		}
		
		.tab-inner-left{
			float: left;
			width: 65%;
		}
		
		.tab-inner-right{
			float: right;
			width: 35%;
			padding-left: 3%;
			
			ul{
				float: left;
				width: 100%;
				
				li{
					float: left;
					width: 100%;
					position: relative;
					font-size: $size-base;
					color: $col-para;
					font-weight: 400;
					padding: 0 0 8px 21px;
					
					&:before{
						position: absolute;
						left: 0;
						top: 3px;
						content: "\f00c";
						font-family: 'FontAwesome';
						font-size: 15px;
						color: $tick-col;						
					}
				}
			}
		}
		h6{
			color: $tab-content;
			padding-bottom: 34px;
		}
		
		.inner-list{
			float: left;
			width: 100%;
			padding-top: 2px;
			
			h6{
				font-size: $size-base;
				font-weight: 400;
				padding-bottom: 10px;
				color: $col-para;
			}
			
			ul{
				float: left;
				width: 100%;
				padding-left: 23px;
				margin-bottom: 21px;
				
				li{
					float: left;
					width: 100%;
					color: $col-para;
					font-size: $size-base;
					font-weight: 400;
					margin-bottom: 3px;
					position: relative;
					padding-left: 17px;
					
					&:before{
						position: absolute;
						left: 0;
						top: 8px;
						content: "";
						background: $bullet-col;
						width: 5px;
						height: 5px;
						border-radius: 100%;
					}
				}
			}
		}
		
		.landmark-distance{
			float: left;
			width: 24.5%;
			
			ul{
				float: left;
				width: 100%;
				
				li{
					float: left;
					width: 100%;
					margin-bottom: 8px;
					
					.landmark-name{
						float: left;
						width: 60%;
						font-weight: 700;
						color: $col-para;
						font-size: $size-base;
					}
					
					.distance{
						float: right;
						width: auto;
						font-weight: 400;
						color: $col-para;
						font-size: $size-base;
					}
				}
			}
		}
		
		.location-info{
			float: right;
			width: 74%;
			padding-left: 92px;
		}
		
		#loaction-map{
			float: right;
			width: 100%;
			margin-top: 18px;
			height: 429px;
		}
		
		.enquiry-form{
			float: left;
			width: 65%;
			margin-top: 7px;
			
			.field-one{
				float: left;
				width: 49%;
				margin-bottom: 11px;
				position: relative;
				
				.enquiry-field{
					float: left;
					width: 100%;
					background: $input-border;
					color: $label-col;
					text-transform: capitalize;
					border: none;
					font-size: $size-menu;
					box-shadow: none;
					height: 54px;
					padding: 5px 35px 5px 14px;
					margin: 0;
				}
				
				&:after{
					position: absolute;
					right: 17px;
					top: 22px;
					content: "";
					background: url(../images/required_icon.png) no-repeat left top;
					width: 6px;
					height: 6px;
				}
			}
			textarea{
				float: left;
				width: 100%;
				background: $input-border;
				color: $label-col;
				text-transform: capitalize;
				border: none;
				font-size: $size-menu;
				box-shadow: none;
				height: 148px;
				resize: none;
				padding: 22px 14px;
				margin-bottom: 9px;
			}
			
			.captcha-block{
				float: right;
				width: 374px;

				.field-one{
					width: 100%;
					margin: 0 0 10px;
				}
				
				.captcha-image{
					float: right;
				}
			}
			
			.primary-button{
				min-width: 375px;
				float: right;
				margin-top: 4px;
				height: 54px;
				padding: 9px 10px 10px;
			}
		}
		
		.enquiry-address{
			float: left;
			width: 25%;
			padding: 2px 0 0 30px;
			
			h6{
				font-weight: 600;
				color: $col-para;
				padding-bottom: 23px;
			}
			
			address{
				float: left;
				width: 100%;
				font-weight: 400;
				color: $tab-content;
				font-size: $size-base;
				line-height: 23px;
				margin-bottom: 21px;
			}
			
			.location-phone{
				float: left;
				width: 100%;
				font-weight: 400;
				color: $tab-content;
				font-size: $size-base;
				padding-bottom: 2px;
			}
			.location-mail{
				float: left;
				width: 100%;
				font-weight: 400;
				color: $tab-content;
				font-size: $size-base;
				
				a{
					color: $secondary-col;
					
					&:hover{
						color: $darker;
					}
				}
			}
			
		}
	}
	
	.accordion{
		float: left;
		width: 100%;
		background: none;		
		border: none;
		position: relative;
		
		& > li{
			margin: 0;
			background: $border-col3;	
			
			& > a{
				background: $border-col3;	
				color: $tab-txt;
				font-weight: 600;
				font-size: $size-tab;
				padding: 17px 30px 19px 29px;
				float: left;
				width: 100%;
			}
			
			&.is-active{
				background: $primary-col;				
				
				& > a{
					background: $primary-col;	
					color: $lighter;
				}
			}
			
			&:first-child{
				margin-left: 0;
			}
			
			.accordion-content{
				float: left;
				width: 100%;
				border: none;
				padding-bottom: 15px;

				.tabs-panel{
					padding: 35px 0 20px;
				}

				.tab-inner-left{
					float: left;
					width: 65%;
				}

				.tab-inner-right{
					float: right;
					width: 35%;
					padding-left: 3%;

					ul{
						float: left;
						width: 100%;
						margin: 0;

						li{
							float: left;
							width: 100%;
							position: relative;
							font-size: $size-base;
							color: $col-para;
							font-weight: 400;
							padding: 0 0 8px 21px;

							&:before{
								position: absolute;
								left: 0;
								top: 3px;
								content: "\f00c";
								font-family: 'FontAwesome';
								font-size: 15px;
								color: $tick-col;						
							}
						}
					}
				}
				h6{
					color: $tab-content;
					padding-bottom: 34px;
				}

				.inner-list{
					float: left;
					width: 100%;
					padding-top: 2px;

					h6{
						font-size: $size-base;
						font-weight: 400;
						padding-bottom: 10px;
						color: $col-para;
					}

					ul{
						float: left;
						width: 100%;
						padding-left: 23px;
						margin-bottom: 21px;

						li{
							float: left;
							width: 100%;
							color: $col-para;
							font-size: $size-base;
							font-weight: 400;
							margin-bottom: 3px;
							position: relative;
							padding-left: 17px;

							&:before{
								position: absolute;
								left: 0;
								top: 8px;
								content: "";
								background: $bullet-col;
								width: 5px;
								height: 5px;
								border-radius: 100%;
							}
						}
					}
				}

				.landmark-distance{
					float: left;
					width: 100%;

					ul{
						float: left;
						width: 100%;
						margin: 0;

						li{
							float: left;
							width: 100%;
							margin-bottom: 8px;

							.landmark-name{
								float: left;
								width: 60%;
								font-weight: 700;
								color: $col-para;
								font-size: $size-base;
							}

							.distance{
								float: right;
								width: auto;
								font-weight: 400;
								color: $col-para;
								font-size: $size-base;
							}
						}
					}
				}

				.location-info{
					float: right;
					width: 100%;
					padding: 20px 0 0 0;
				}

				#loaction-map{
					float: right;
					width: 100%;
					margin-top: 18px;
					height: 300px;
				}

				.enquiry-form{
					float: left;
					width: 100%;
					margin-top: 7px;

					.field-one{
						float: left;
						width: 100%;
						margin-bottom: 11px;
						position: relative;

						.enquiry-field{
							float: left;
							width: 100%;
							background: $input-border;
							color: $label-col;
							text-transform: capitalize;
							border: none;
							font-size: $size-menu;
							box-shadow: none;
							height: 54px;
							padding: 5px 35px 5px 14px;
							margin: 0;
						}

						&:after{
							position: absolute;
							right: 17px;
							top: 22px;
							content: "";
							background: url(../images/required_icon.png) no-repeat left top;
							width: 6px;
							height: 6px;
						}
					}
					textarea{
						float: left;
						width: 100%;
						background: $input-border;
						color: $label-col;
						text-transform: capitalize;
						border: none;
						font-size: $size-menu;
						box-shadow: none;
						height: 148px;
						resize: none;
						padding: 22px 14px;
						margin-bottom: 9px;
					}

					.captcha-block{
						float: right;
						width: 100%;

						.field-one{
							width: 190px;
							margin: 0;
						}

						.captcha-image{
							float: left;
							margin-left: 10px;
						}
					}

					.primary-button{
						min-width: 100%;
						max-width: 100%;
						float: left;
						margin-top: 4px;
						height: 54px;
						padding: 9px 10px 10px;
					}
				}

				.enquiry-address{
					float: left;
					width: 100%;
					padding: 20px 0 0 0;

					h6{
						font-weight: 600;
						color: $col-para;
						padding-bottom: 10px;
					}

					address{
						float: left;
						width: 100%;
						font-weight: 400;
						color: $tab-content;
						font-size: $size-base;
						line-height: 23px;
						margin-bottom: 21px;
					}

					.location-phone{
						float: left;
						width: 100%;
						font-weight: 400;
						color: $tab-content;
						font-size: $size-base;
						padding-bottom: 2px;
					}
					.location-mail{
						float: left;
						width: 100%;
						font-weight: 400;
						color: $tab-content;
						font-size: $size-base;

						a{
							color: $secondary-col;

							&:hover{
								color: $darker;
							}
						}
					}

				}
			}
		}
	}	
}

.detail-tab .accordion > li .accordion-content .enquiry-form .field-one .enquiry-field::-webkit-input-placeholder{
	opacity: 1;
	color: $label-col;
}
.detail-tab .accordion > li .accordion-content .enquiry-form .field-one .enquiry-field:-moz-placeholder {
	opacity: 1;
	color: $label-col;
}
.detail-tab .accordion > li .accordion-content .enquiry-form .field-one .enquiry-field::-moz-placeholder {
	opacity: 1;
	color: $label-col;
}
.detail-tab .accordion > li .accordion-content .enquiry-form .field-one .enquiry-field:-ms-input-placeholder {
	opacity: 1;
	color: $label-col;
}


.detail-tab .tabs-content .enquiry-form .field-one .enquiry-field::-webkit-input-placeholder{
	opacity: 1;
	color: $label-col;
}
.detail-tab .tabs-content .enquiry-form .field-one .enquiry-field:-moz-placeholder {
	opacity: 1;
	color: $label-col;
}
.detail-tab .tabs-content .enquiry-form .field-one .enquiry-field::-moz-placeholder {
	opacity: 1;
	color: $label-col;
}
.detail-tab .tabs-content .enquiry-form .field-one .enquiry-field:-ms-input-placeholder {
	opacity: 1;
	color: $label-col;
}

.condition-agree{
	float: left;
	width: 325px;
	margin:10px 0 105px;
	
	.icheckbox-outer{
		float: left;
		width: 100%;
		
		.icheckbox{
			float: left;
			vertical-align: middle;
			margin: 0;
			padding: 0;
			width: 38px;
			height: 34px;
			background: url(../images/checkbox2.png) no-repeat 0 0;
			border: none;
			cursor: pointer;	
		}
		.icheckbox.checked {
			background-position: 0 -34px;
		}
		
		label{
			float: left;
			color: $label-col;
			font-style: italic;
			font-size: $size-base;
			width: 225px;
			font-family: $italicfont;
			text-align: left;
			padding-left: 16px;
			line-height: 13px;
			cursor: pointer;

			a{
				float: left;
				color: $label-col;
				font-style: italic;
				font-size: $size-base;
				text-decoration: underline;
				font-family: $italicfont;
				margin-top: 5px;
			}
		}
	}
}


/*====================================
	Booking Form
=======================================*/
.calendar-form-outer{
    width: 100%;
    float: left;
    padding: 26px 0 0;
	margin-bottom: 120px;
}

.calendar-section{
    width: 100%;
    float: left;
    img{
        width: 100%;
        float: left;
    }
}

.form-section{
    width: 100%;
    float: left;
    margin: 41px 0 0;
    .form-block{
        width: 100%;
        float: left;
        .block-title{
            width: 100%;
            float: left;
            background: $primary-col;
            padding: 14px 20px 13px;
            h6{
                font-weight: $main-bold;
                color: $lighter;
            }
        }
        .block-content{
            width: 100%;
            float: left;
            padding: 18px 15px 14px;
            margin: 0 0 11px;
            background: $block-color;
            h6{
                font-size: $size-base;
                font-weight: $main-bold;
                color: $lighter;
                line-height: 16px;
                color: $tab-content;
                padding: 0 0 15px 5px;
            }
            .date-time{
                width: 100%;
                float: left;
                padding: 0 0 6px;
                margin: 0 0 14px;
                .single-field{
                    width: 47.5%;
                    max-width: 100%;
                    float: left;
                    margin: 0;
                    padding-bottom: 8px;
                    &.date-select:after{
                        width: 33px;
                        height: 33px;
                        background-size: 33px 33px;
                        position: absolute;
                        right: 1px;
                        top: 2px;
                    }
                    .mainfield{
                        border-color: $border-col2;
                    }
                    &.time-select{
                        position: relative;
						
						.time-btn{
							cursor: pointer;
							float: right;
							position: absolute;
							right: 6px;
							top: 7px;
							z-index: 1;
							
							img{
								width: 22px;
                        		height: 24px;
								float: left;
							}
						}
                    }
                    &.time-select:after{
                        width: 22px;
                        height: 24px;
                        content: "";
                        position: absolute;
                        right: 6px;
                        top: 7px;
                       /* background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDg1IDg1IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA4NSA4NTsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2ZpbGw6I0YzNkIyMTt9DQo8L3N0eWxlPg0KPHBhdGggY2xhc3M9InN0MCIgZD0iTTQyLjUsODVDNjUuOSw4NSw4NSw2NS45LDg1LDQyLjVTNjUuOSwwLDQyLjUsMFMwLDE5LjEsMCw0Mi41UzE5LjEsODUsNDIuNSw4NXogTTQyLjUsNQ0KCUM2My4yLDUsODAsMjEuOCw4MCw0Mi41UzYzLjIsODAsNDIuNSw4MFM1LDYzLjIsNSw0Mi41UzIxLjgsNSw0Mi41LDV6Ii8+DQo8cG9seWdvbiBjbGFzcz0ic3QwIiBwb2ludHM9IjYyLjUsNDAgNDUsNDAgNDUsMTIuNSA0MCwxMi41IDQwLDQ1IDYyLjUsNDUgIi8+DQo8L3N2Zz4=) !important;*/
                        background-size: 22px 24px;
                        background-repeat: no-repeat;                        
                    }
                }
                &.border{
                    border-bottom: 1px solid $border-col2;
                }
            }
            &.options{
                padding: 22px 15px;
                h6{
                    padding: 0 0 13px 6px;
                }
                .icheckbox-outer{
                    text-align: left;
                    padding: 0 0 0 5px;
                    .icheckbox{
                        width: 14px;
                        height: 14px;
                        background: url("../images/checkbox1.png") no-repeat;
                        margin: 2px 0 0;
                        &.checked{
                            background-position: 0 -14px;
                        }
                    }
                    li{
                        width: 100%;
                        float: left;
                        display: block;
                        margin: 0 0 5px;
                        label{
                            width: 95%;
                            color: $label-col;
                            padding-left: 9px;
							cursor: pointer;
                        }
                    }  
                }
            }
            &.price-blk{
                background: $secondary-col;
                padding: 21px 21px 14px;
                .price-sec{
                    width: 100%;
                    float: left;
                    .price-list{
                        width: 100%;
                        float: left;
                        padding: 0 0 7px;
                        span{
                            width: auto;
                            float: left;
                            font-family: $mainfont;
                            font-weight: $main-regular;
                            font-size: $size-tab;
                            color: $lighter;
                            &.price{
                                float: right;
                                text-align: right;
                                &.total{
                                    font-weight: $main-bold;
                                }
                            }
                        }
                        &.total-sec{
                            border-top: 1px solid $border-col4;
                        }
                    }
                }
            }
            .personal-form{
                width: 100%;
                float: left;
                .details-list{
                    width: 100%;
                    float: left;
                    .single-field{
                        width: 100%;
                        max-width: 100%;
                        margin: 0;
                        &.half{
                            width: 47.5%;
                        }
                        .mainfield{
                            border-color: $border-col2;
                        }
                    }
                    &.sbt-btn{
                        margin: 27px 0 0; 
                    }
                    .primary-button{
                        width: 100%;
                        max-width: 100%;
                    }
                }
            }
        }
    }
}

 
.ui-timepicker-wrapper {
	overflow-y: auto;
	max-height: 150px;
	width: 165px;
	background: #fff;
	border: 1px solid #ddd;
	-webkit-box-shadow:0 5px 10px rgba(0,0,0,0.2);
	-moz-box-shadow:0 5px 10px rgba(0,0,0,0.2);
	box-shadow:0 5px 10px rgba(0,0,0,0.2);
	outline: none;
	z-index: 10001;
	margin: 0;
}

.ui-timepicker-wrapper.ui-timepicker-with-duration {
	width: 13em;
}

.ui-timepicker-wrapper.ui-timepicker-with-duration.ui-timepicker-step-30,
.ui-timepicker-wrapper.ui-timepicker-with-duration.ui-timepicker-step-60 {
	width: 11em;
}

.ui-timepicker-list {
	margin: 0;
	padding: 0;
	list-style: none;
}

.ui-timepicker-duration {
	margin-left: 5px; color: #888;
}

.ui-timepicker-list:hover .ui-timepicker-duration {
	color: #888;
}

.ui-timepicker-list li {
	padding: 6px 14px;
	cursor: pointer;
	white-space: nowrap;
	color: #000;
	list-style: none;
	margin: 0;
}

.ui-timepicker-list:hover .ui-timepicker-selected {
	background: $lighter; 
	color: #000;
}

li.ui-timepicker-selected,
.ui-timepicker-list li:hover,
.ui-timepicker-list .ui-timepicker-selected:hover {
	background: $primary-col; 
	color:$lighter;
}

li.ui-timepicker-selected .ui-timepicker-duration,
.ui-timepicker-list li:hover .ui-timepicker-duration {
	color: #ccc;
}

.ui-timepicker-list li.ui-timepicker-disabled,
.ui-timepicker-list li.ui-timepicker-disabled:hover,
.ui-timepicker-list li.ui-timepicker-selected.ui-timepicker-disabled {
	color: #888;
	cursor: default;
}

.ui-timepicker-list li.ui-timepicker-disabled:hover,
.ui-timepicker-list li.ui-timepicker-selected.ui-timepicker-disabled {
	background: #f2f2f2;
}

.form-section .form-block .block-content.price-blk .price-sec.total-sec{
    border-top: 1px solid $border-col4;
    margin: 10px 0 0;
    padding: 16px 0 5px;
}

.form-section .form-block .block-content .date-time .single-field.time-select .mainfield{
    padding: 0 32px 0 14px;
}


/*time picker*/
.ui-widget-header{
	background:$primary-col;
	color: $lighter;
	padding: 5px 0 6px;
	font-size: $size-menu;
	border: none;
	border-radius: 0;
}
.ui-timepicker .periods{
	color: $primary-col;
	padding: 7px 0 9px;
	border: none;
	vertical-align: middle;
	padding: 0 6px;
	text-align: center;
}
.ui-timepicker-hours{
	
}
.ui-timepicker-minutes{
	
}

.ui-timepicker table{
	font-size: $size-small;
    height: auto;
    margin: 0;
    padding: 0;
	font-family: $mainfont;
	
}
.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default{
	background: $lighter;
	color: $primary-col;
	border: none;
	text-align: center;
}
.ui-datepicker-calendar table tbody tr:nth-child(2n){
	background: $lighter!important;
}
.ui-datepicker-calendar tbody td:first-child, tbody td:last-child{
	background: $lighter;
}
.ui-datepicker-calendar table thead, table tbody, table tfoot{
	background: $lighter;
}
.ui-timepicker tbody{
	background: $lighter;
}
.ui-timepicker tbody td:first-child, tbody td:last-child{
	background: $lighter;
}
.ui-timepicker table tbody tr:nth-child(2n){
	background: $lighter;
}


//calender
.calender{
	float: left;
    padding: 0;
    position: relative;
    width: 100%;
    #year{
        margin: -46px 0 0;
    }
    #year ul li.prev {
        background: url(../images/pre.png) no-repeat center center;
        width: 103px;
        float: left;
        font-size: 0;
        cursor: pointer;
        height: 18px;
    }
    #year ul li.selected-year {
        font-size: $size-base;
        color: $semi-dark;
        padding: 0 8px;
        margin: -2px 0 0;
    }
    #year ul li.next {
        background: url(../images/next.png) no-repeat center center;
        width: 103px;
        float: left;
        font-size: 0;
        cursor: pointer;
        height: 18px;
    }



    th.month_name {
        font-weight: $main-bold;
        font-family: $mainfont;
        font-size: $size-h6;
        background: $secondary-col;
        color: $lighter;
        text-transform: capitalize;
        float: left;
        width: 100%;
        padding: 12px 0;
        text-align: center;
    }
    table tr th, table tr td {
        text-align: center;
        line-height: 21px;
        text-transform: lowercase;
        font-size: $size-small;
        padding: .500em;
        font-family: $subfont;
        color: $calender-txt;
        background: $lighter;
}

.calender table tr th, .calender table tr td {
    text-align: center;
    line-height: 21px;
    text-transform: lowercase;
    font-size: 13px;
    padding: .500em;
    color: $calender-txt;
    background: $lighter;
}

	
	.table-outer {
        
		border: 1px solid #d5d5d5;
		float: left;
		margin: 0 8px 14px 0;
		padding: 0px;
		width: 184px;
        
		table{
			float: left;
			width: 100%;
            margin: 0;
            height: 285px;
            thead{
                float: left;
			    width: 100%;
                tr{
                    float: left;
			        width: 100%;
                    display: table;
                    
                }
            }
            tbody{
                float: left;
			    width: 100%;
                border: none;
                td:first-child, td:last-child {
                    background: $lighter;
                }
            }
		}
	}
}

#year-calendar p {
  font-size: 24pt;
  text-align: center;
  padding: 0;
  margin: 0;
}

#date-range {
  font-size: 12pt;
  text-align: center;
}

#year {
  font-size: 24pt;
  text-align: right;
  padding: 0;
  margin: 0;
  max-height: 1em;
float: right;
    width: 100%;
}

#year ul {
  display: inline-block;
  text-align: center;
  padding: 0;
  margin: 0;
}

#year ul li {
  float: left;
  list-style-type: none;
  padding-left: 6pt;
  padding-right: 6pt;
  margin: 0;
}

#year ul li.selected-year {
  font-size: 16pt;
}

th, td {
  padding: 1px;
  transition: all 0.3s ease;
}

tbody td:first-child, tbody td:last-child {
  background: #F5F5F5;
}

thead {
  font-weight: bold;
  text-transform: capitalize;
}

th.month_name {
  text-align: center;
}

th.month_name.current {
  color: #CB6C6B;
  border-bottom: 2px #CB6C6B solid;
  padding-bottom: 0px;
}

th.current {
  color: #CB6C6B;
}

td.current {
  background-color: #CB6C6B;
  color: white;
}

.calender td.selected {
  background-color: $calender-selected !important;
  color: black !important;
}
td.select-start.selected {
    position: relative;
    background: $lighter !important;
    z-index: 1;
}
td.select-start.selected::before {
    content: "";
    border-bottom: 33px solid $calender-selected;
    border-left: 27px solid transparent;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
}
td.select-end.selected {
    position: relative;
    background: $lighter !important;
    z-index: 1;
}
td.select-end.selected::before {
    content: "";
    border-top: 33px solid $calender-selected;
    border-right: 27px solid transparent;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
}
.calender-title{
    float: left;
    width: 100%;
    padding: 0 0 20px;
    .calender-left{
        float: left;
        h6{
            color: $tab-content;
            font-weight: $main-bold;
        }
    }
}
.calender table thead tr th{
    background: $input-border;
}





/*====================================
	Availability Calender
=======================================*/
.availability-calender {
    float: left;
    width: 100%;
}


/*====================================
	Rate Block
=======================================*/
.rate-details{
	float: left;
	width: 100%;
	margin-top: 30px;
}
.rate-block{
	float: left;
    width: 65%;
	
	h6{
		color: $tab-content;
		padding-bottom: 31px;
	}
	
	ul{
		float: left;
		width: 100%;
        margin-bottom: 2rem;
		
		li{
			float: left;
			width: 100%;
			margin-bottom: 6px;
			
			.rate-left{
				float: left;
				width: 64.6%;
				background: $border-col;
				padding: 16px 23px 15px 31px;
				
				.date-period{
					font-weight: 700;
					font-size: $size-menu;
					color: $semi-dark;
					float: left;
					width: 110px;
				}
				
				.rate-per_day{
					font-weight: 400;
					font-size: $size-base;
					color: $semi-dark;
					float: right;
					font-style: italic;
					line-height: 23px;
					width: auto;
				}
			}
			
			.rate-right{
				float: left;
				width: 32.8%;
				background: $primary-col;
				padding: 16px 10px;
				text-align: center;
				
				.rate-per_week{
					font-weight: 700;
					font-size: $size-menu;
					color: $lighter;
					float: left;
					width: 100%;
				}
			}			
		}
	}
}


/*====================================
	Extras Block
=======================================*/
.extras-block {
    float: right;
    width: 50%;
	padding:0 9px 0 5px;
	
	h6{
		color: $tab-content;
		padding-bottom: 24px;
	}
	
	ul{
		float: left;
    	width: 100%;
		
		li{
			float: left;
    		width: 100%;
			border-bottom: 1px solid $input-border;
			padding: 7px 22px 8px 10px;
			display: table;
			
			.extra-label{
				display: table-cell;
				vertical-align: middle;
				width: 70%;
				color: $col-para;
				font-size: $size-base;
				font-weight: 400;
			}
			
			.extra-amount{
				width: auto;
				color: $col-para;
				font-size: $size-base;
				font-weight: 700;
				display: table-cell;
				vertical-align: middle;
				text-align: right;
			}
		}
	}
}



.ui-timepicker-table tbody td:first-child, tbody td:last-child{
	background: $lighter;
}


/*===============================================
    Media queries
==================================================*/

@media only screen and (min-width: 992px) and (max-width: 1169px) { 
	.rate-block ul li .rate-left{width: 64.8%;padding: 16px 18px 15px 15px;}
	.rate-block ul li .rate-right{width: 35%;padding: 16px 6px;}
	
	 .details-block .details-single .detail-share { padding: 8px 0px 9px;}
    .details-block .details-single .detail-download a span {font-size: $size-small; padding-left: 6px;}
    .detail-page-rate .detail-rate-text { padding: 0 5px 0 0;}
    .detail-rate-button .primary-button {font-size: 20px; min-width: 0; max-width: none;}
    .details-block .book-button .primary-button { padding: 16px 10px 20px;}
    .galleria-theme-classic .galleria-carousel .galleria-thumbnails-list { margin-left: 40px; margin-right: 40px;}
    .galleria-theme-classic .galleria-carousel .galleria-thumbnails-list {max-width: none; margin-left: 7%; margin-right: 7%; width: 86%; text-align: center;}
    .detail-slider .galleria-theme-classic .galleria-thumbnails .galleria-image {width: 127px !important; float: none; display: inline-block; margin: 0 6px;}
	
	 .form-section .form-block .block-content .date-time .single-field .mainfield {font-size: 11px;padding: 0 10px;}
    .sbSelector {font-size: 11px;}
    .form-section .form-block .block-content.options .icheckbox-outer li label {width: 94%;}
    .form-section .form-block .block-content.price-blk .price-sec .price-list span {font-size: 14px;}

	.details-block .details-single .detail-list li label{ font-size: 13px;}
	.condition-agree .icheckbox-outer label{padding-left: 8px;}
	.condition-agree{width: 265px;}
    .details-block .details-single { padding: 12px 14px;}
    
    .calender .table-outer { width: 37.5%; max-width: 180px;}

}



@media only screen and (min-width: 768px) and (max-width: 991px) {
	.details-block .details-single .detail-share { padding: 8px 0px 9px;}
    .details-block .details-single .detail-download a span {font-size: $size-small; padding-left: 6px;}
    .detail-page-rate .detail-rate-text { padding: 0 5px 0 0;}
    .detail-rate-button .primary-button {font-size: 12px;min-width: 0; max-width: none; padding: 10px 5px 12px;}
    .details-block .book-button .primary-button { padding: 12px 10px 14px;}
    .galleria-theme-classic .galleria-carousel .galleria-thumbnails-list { margin-left: 40px; margin-right: 40px;}
    .galleria-theme-classic .galleria-carousel .galleria-thumbnails-list {max-width: 550px;}
    .detail-slider .galleria-theme-classic .galleria-thumbnails .galleria-image {width: 124px !important; float: none; display: inline-block; margin: 0 6px;}
    .details-block .details-single .detail-list li label { font-size: 12px;}
    .details-block .details-single .detail-list li span {max-width: 121px; font-size: 11px;}
    .details-block .details-single .detail-share li a { height: 20px; width: 20px; line-height: 20px;}
    .details-block .details-single .detail-share li a i {font-size: 16px; line-height: 20px;}
    .details-block .details-single .detail-download a span { font-size: 9px; padding-left: 4px; line-height: 22px;}
    .details-block .details-single .detail-download a img{ max-width: 20px;}
    .detail-page-rate .detail-rate-text h6 { font-size: 12px; line-height: 14px;}
    .detail-page-rate .detail-rate-text a {font-size: 12px;}
    .detail-page-title { padding: 0 0 10px;}
    .galleria-theme-classic .galleria-image-nav {top: 60%;}
    .galleria-theme-classic .galleria-carousel .galleria-thumbnails-list { margin-left: 7%;  margin-right: 7%; width: 86%; text-align: center;}
	
	 
    .form-section .form-block .block-content {padding: 18px 15px 0;}
    .form-section .form-block .block-content .date-time {padding: 0 0 7px;}
    .form-section .form-block .block-content .date-time .single-field {width: 100%;}
    .form-section .form-block .block-content.options .icheckbox-outer li label {width: 92%;}
    .form-section .form-block .block-content.price-blk .price-sec .price-list span {font-size: 14px;}
    .form-section .form-block .block-content .personal-form .details-list .single-field.half {width: 100%;}
	
	.detail-tab .tabs-content .landmark-distance{width: 30%;}
	.detail-tab .tabs-content .location-info{padding-left: 35px;width: 70%;}
	.detail-tab .tabs-content .enquiry-form{width: 76%;}
	.condition-agree .icheckbox-outer label{padding-left: 12px;}
	.condition-agree{width: 100%;margin: 10px 0 20px;}
	.detail-tab .tabs-content .enquiry-address{padding: 2px 0 0 20px;width: 24%;}
	.detail-tab .tabs-content .enquiry-form .captcha-block{width: 100%;}
	.detail-tab .tabs-content .enquiry-form .captcha-block .captcha-image{float: left;margin-left: 0;}
	.detail-tab .tabs-content .enquiry-form .primary-button{float: left;min-width: 380px;}
	.rate-block{width: 100%;}
	.extras-block{width: 100%;padding: 15px 9px 0 0;}
	.extras-block h6{padding-bottom: 10px;}
	.rate-block h6{padding-bottom: 10px;}
    .details-block .details-single .detail-download a img { width: 24px; height: 26px;}
    .details-block .details-single .detail-download { float: right;  padding: 5px 0px 0;}
    .calender .table-outer { width: 37.5%; max-width: 180px;}
	.detail-tab .accordion > li .accordion-content .enquiry-form .captcha-block .captcha-image{width: 100%;}
}

@media only screen and (max-width: 767px) {
	.rate-block{width: 100%;}
	.rate-block h6{padding-bottom: 10px;}
	.extras-block{width: 100%;float: left;margin-top: 30px;}
	.extras-block h6{padding-bottom: 10px;}
	
	.details-block .details-single .detail-share { padding: 0;}
    .details-block .details-single .detail-download a span {font-size: $size-small; padding-left: 6px;}
    .detail-page-rate .detail-rate-text { padding: 0 0px 0 16px; float: right;}
    .detail-rate-button .primary-button {font-size: 18px;min-width: 0; max-width: none; padding: 8px 20px 10px;}
    .details-block .book-button .primary-button { padding: 10px 10px 12px; font-size: 24px;}
    .galleria-theme-classic .galleria-carousel .galleria-thumbnails-list { margin-left: 40px; margin-right: 40px;}
    .galleria-theme-classic .galleria-carousel .galleria-thumbnails-list {max-width: 550px;}
    .detail-slider .galleria-theme-classic .galleria-thumbnails .galleria-image {width: 124px !important; }
    .details-block .details-single .detail-list li label { font-size: 13px;}
    .details-block .details-single .detail-list li span {font-size: 13px;}
	.details-block .details-single{padding: 18px;}
    .details-block .details-single .detail-share li a { height: 30px; width: 30px; line-height: 32px;}
    .details-block .details-single .detail-share li a i {font-size: 16px; line-height: 20px;}
    .details-block .details-single .detail-download a span { font-size: 12px; padding-left: 10px; line-height: 30px;}
    .detail-page-rate .detail-rate-text h6 { font-size: 12px; line-height: 14px; text-align: left; padding: 3px 0 2px;}
    .detail-page-rate .detail-rate-text a {font-size: 12px;}
    .detail-page-title { padding: 0 0 20px;}
    .detail-page-rate { float: left; padding: 0 0 20px;}
    .detail-page-title h4 { font-size: 20px; line-height: 24px;}
    .detail-page-slider .galleria {height: auto;}
    .detail-slider .galleria-theme-classic .galleria-thumbnails-container { display: none;}
    .details-block .details-single .detail-download { padding: 0;}
    .detail-outer { padding: 30px 0 0;}
    .galleria-theme-classic .galleria-image-nav {top: 60%;}
    .calender-title { padding: 0 0 10px;}
	
	 .form-section .form-block .block-content {padding: 18px 15px 0;}
    .form-section .form-block .block-content .date-time {padding: 0 0 7px;}
    .form-section .form-block .block-content .date-time .single-field {width: 100%;}
    .form-section .form-block .block-content.options .icheckbox-outer li label {width: 92%;font-size: 14px;}
    .form-section .form-block .block-content.price-blk .price-sec .price-list span {font-size: 14px;}
    .form-section .form-block .block-content .personal-form .details-list .single-field.half {width: 100%;}
    .form-section .form-block .block-content.options .icheckbox-outer .icheckbox {margin: 3px 0 0;}
    .form-section .form-block .block-content.options {padding: 22px 15px 15px;}
    .form-section .form-block .block-content.form {padding: 18px 15px;}
	
	.detail-rate-button{width: auto;}
	.detail-page-rate{width: 100%; max-width: 296px;}
	
	.detail-tab .accordion > li .accordion-content .tab-inner-left{width: 100%;}
	.detail-tab .accordion > li .accordion-content .tab-inner-right{width: 100%;padding: 20px 0 0 0;}
	.detail-tab .accordion > li .accordion-content h6{padding-bottom: 10px;}
	
	.condition-agree{margin: 10px 0 20px;}
	.detail-tab{margin-top: 20px;}
	.detail-tab .accordion > li .accordion-content .enquiry-form .field-one .enquiry-field{ font-size: 17px;height: 46px;padding: 2px 35px 5px 14px;}
	.detail-tab .accordion > li .accordion-content .enquiry-form .captcha-block .field-one{width: 100%;margin-bottom: 11px;}
    
    .rate-text { padding: 0 0px 0 10px; float: right;}
    .details-block .details-single .detail-download a img { width: 30px; height: 32px;}

    .calender .table-outer { width: 40%; max-width: 180px; float: none; display: inline-block;}
    .calender-title { padding: 10px 0 10px;}
    .calender #year {  margin: -43px 0 0;}
    #year-calendar { text-align: center;}
	.detail-tab .accordion > li .accordion-content .enquiry-form .captcha-block .captcha-image{margin: 0;width: 100%;}
	.captcha-image .g-recaptcha .rc-anchor.rc-anchor-normal.rc-anchor-light{width: 99%;}
}



@media only screen and (max-width: 479px) {
	.rate-block ul li .rate-left{ padding: 16px 13px 15px; width: 65%;}
	.rate-block ul li .rate-left .date-period{font-size: 13px;width: auto;}
	.rate-block ul li .rate-left .rate-per_day{font-size: 12px;line-height: 19px;}
	.rate-block ul li .rate-right{width: 35%;padding: 16px 9px;}
	.rate-block ul li .rate-right .rate-per_week{font-size: 12px;}
	
	.detail-tab .accordion > li .accordion-content{padding: 10px;}
	.detail-tab .accordion > li .accordion-content .enquiry-form .captcha-block .field-one{margin: 0 0 10px;width: 100%;}
	.detail-tab .accordion > li .accordion-content .enquiry-form .captcha-block .captcha-image{margin: 0;}
	.calender-title .calender-right{margin: 8px 0 2px;width: 100%;}
	.calendar-form-outer{padding: 10px 0 0;}
	.details-block .details-single{padding: 15px;}
	.details-block .details-single .detail-download{padding: 15px 0 0;width: 100%;}
	.detail-download img{float: left;}
	.details-block .details-single .detail-download a span{float: left;}
    .detail-page-rate .detail-rate-text { padding: 0 0px 0 10px;}

	.ui-timepicker-minutes{float: left;width: 100%;}
	.ui-timepicker-hours{float: left;width: 100%;}
    
    .calender .table-outer { width: 62%; margin: 5px;}
    .calender #year { margin: 0px 0 14px;}
    .calender-title{ text-align: center;}
    #year ul{text-align: center; float: none;}
    .calender #year{text-align: center;}
    .calender-title .calender-left{width: 100%; text-align: center;}
}





