@import  "_variables"; 
 
// -------------------------- -------------------------- -------------------------- -------------------------- 

@mixin border-radius($radius: 5px) {
    -moz-border-radius:    $radius;
    -webkit-border-radius: $radius;
    border-radius:         $radius;
} 
 @mixin transition($transition-property, $transition-time, $method) {  
//@include transition(all, 0.3s, ease-in-out);
    -webkit-transition: $transition-property $transition-time $method;
       -moz-transition: $transition-property $transition-time $method;
        -ms-transition: $transition-property $transition-time $method;
         -o-transition: $transition-property $transition-time $method;
            transition: $transition-property $transition-time $method;
}
@mixin transit {
  -webkit-transition: all 300ms ease-in 0s;
     -moz-transition: all 300ms ease-in 0s;
	  -ms-transition: all 300ms ease-in 0s;
	   -o-transition: all 300ms ease-in 0s;
          transition: all 300ms ease-in 0s;
	
	
}
@mixin transform-translatey($val){ //@include transform-translatey(-50%);
    -webkit-transform: translateY($val);
    -moz-transform   : translateY($val);
    -o-transform     :translateY($val);
    -ms-transform    :translateY($val);
    transform        : translateY($val);
}
@mixin transform-translatex($val){ //@include transform-translatex(-50%);
    -webkit-transform: translateX($val);
    -moz-transform   : translateX($val);
    -o-transform     :translateX($val);
    -ms-transform    :translateX($val);
    transform        : translateX($val);
}
 
/*** Button Style ****************************************************/
 
 


.primary-button {
    width: auto;
    float: left;
    text-align: center;
    height: 40px;
    padding: 8px 10px 10px;
	/*min-width: 175px;
	max-width: 175px;*/
    font-family: $mainfont;
	font-weight: 600;
	font-size: $size-tab;
	color: $lighter;
    border: none;
    background: $primary-col;
    border: none;
    white-space: nowrap; 
    position: relative; 
    @include transit;
	text-transform: uppercase;
	cursor: pointer;
    z-index: 20;
	
		&:hover, 
		&:focus, 
		&:active{
		 	background: $bg2;
			color: $lighter;
		}
}

.secondary-button {
    width: auto;
    float: left;
    text-align: center;
    height: 40px;
    padding: 8px 10px 10px;
	/*min-width: 175px;
	max-width: 175px;*/
    font-family: $mainfont;
	font-weight: 600;
	font-size: $size-tab;
	color: $lighter;
    border: none;
    background: $secondary-col;
    border: none;
    white-space: nowrap; 
    position: relative; 
    @include transit;
	text-transform: uppercase;
	cursor: pointer;
    z-index: 20;
	
		&:hover, 
		&:focus, 
		&:active{
		 	background: $bg3;
			color: $lighter;
		}
}

.mainfield{
	float: left;
	width: 100%;
	background: $input-bg;
	border: 1px solid $input-border;
	border-right: none;
	border-bottom: none;
	padding: 0 14px;
	box-shadow: none;
	margin: 0;
	height: 36px;
	line-height: 36px;
	font-family: $mainfont; 
	font-size: $size-base;
	color: $tab-content;
	
	&:active,
	&:focus{
		box-shadow: none;
		border-right: none;
		border-bottom: none;
	}
}

.maintextarea{
  float: left;
  width: 100%;
  background: $input-bg;
  border: 1px solid $input-border;
  border-right: none;
  border-bottom: none;
  padding: 8px 14px;
  box-shadow: none;
  margin: 0;
  height: 80px;
  line-height: 18px;
  font-family: $mainfont; 
  font-size: $size-base;
  color: $tab-content;
  resize: none;
  
  &:active,
  &:focus{
    border: 1px solid $input-border;
    box-shadow: none;
    border-right: none;
    border-bottom: none;
  }
}

textarea::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $tab-content;
  opacity: 1;
}
textarea::-moz-placeholder { /* Firefox 19+ */
  color: $tab-content;
  opacity: 1;
}
textarea:-ms-input-placeholder { /* IE 10+ */
  color: $tab-content;
  opacity: 1;
}
textarea:-moz-placeholder { /* Firefox 18- */
  color: $tab-content;
  opacity: 1;
}

@mixin font-size($size) {
    font-size: $size + px;
    font-size: $size / 16 + em;
    font-size: $size / 16 + rem;
}

p.clearfix {
	clear: both !important;
	margin: 0 !important;
	padding: 0 !important;
	width: 0 !important;
	height: 0 !important;
}
























