/*===============================================
    Header template 
==================================================*/
.off-canvas{
	z-index: 9999;
}
.js-off-canvas-exit {
    background: rgba(0, 0, 0, .8);
}
.off-canvas.position-right{
	right:-300px; 
	@include transit; 
	height: 100%; 
	top: 0;
	backface-visibility: visible;
	-moz-transform: translatex(300px);
    -webkit-transform: translatex(300px);
    -ms-transform: translatex(300px);
	-o-transform: translatex(300px);
    transform: translatex(300px);
}
.is-open-right {
	-moz-transform: translateY(-250%);
    -webkit-transform: translateX(-250px);
    -ms-transform: translateX(-250px);
	-o-transform: translateY(-250%);
    transform: translateX(-250px);
}
.is-open-left {
   	-moz-transform: translateY(-250%);
    -webkit-transform: translateX(-250px);
    -ms-transform: translateX(-250px);
	-o-transform: translateY(-250%);
    transform: translateX(-250px);
}
.off-canvas-content,
.off-canvas-content {
    background: transparent;
	min-height: 100%;
	z-index: 1;
	width: 100%;
}
.off-canvas.is-open {
	backface-visibility: visible;
	-moz-transform: translate(300px, 0);
    -webkit-transform: translate(300px, 0);
    -ms-transform: translate(300px, 0);
	-o-transform: translate(300px, 0);
    transform: translate(300px, 0);
	background: $lighter;
}
.position-right.is-open ~ .off-canvas-content {
	-moz-transform: translate(0px, 0);
    -webkit-transform: translate(0px, 0);
    -ms-transform: translate(0px, 0);
	-o-transform: translate(0px, 0);
    transform: translate(0px, 0);
}  
.js-off-canvas-overlay.is-overlay-fixed {
    position: fixed;
}
.js-off-canvas-overlay {
    background: rgba(0, 0, 0, 0.25) none repeat scroll 0 0;
    height: 100%;
    left: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    transition: opacity 0.5s ease 0s, visibility 0.5s ease 0s;
    visibility: hidden;
    width: 100%;
}
.off-canvas-wrapper{
	overflow: hidden;
}
.submenu.is-drilldown-submenu{
	 height: 100%;
	 margin: 0;
	 background: $lighter;
 }
 .offcanvas-trigger .offcanvas-outer {
     right: 0;
 } 
.off-canvas-exit.enable {     
	 float: left;
	 height: 100%;
	 left: 0;
	 position: absolute;
	 top: 0;
	 width: 100%;
	 z-index: 1;
}
.off-canvas-content, .off-canvas-content{
 	float: left;
}
.page-outer {
	 margin: 0;
	 padding: 0;
	 transition: all 300ms ease-in-out 0s;
} 
.right-off-canvas .main-menu {
 	display: none;
}
.position-right.is-transition-push::after{
	box-shadow: none;
}
.position-right{
	width: 300px;
}

.header-container{
	float: left;
	width: 100%;
	padding-bottom: 6px;
}
.header-top{
	float: left;
	width: 100%;
	background: $header-top-bg;
	padding: 14px 0 12px;
}
.top-right {
    float: right;
	width: auto;
}
.about-rentablanca{
	float: left;
	width: 100%;
	font-size: $size-link;
	color: $white-txt;
	
	span{
		color: $lighter;
		font-weight: 600;
	}
}
.email{
	float: left;
	width: auto;
	font-family: $mainfont;
	font-weight: $main-semibold;
	font-size: $size-base;
	color: $lighter;
	margin-right: 63px;
	
	a{
		font-family: $mainfont;
		font-weight: $main-semibold;
		font-size: $size-base;
		color: $lighter;
		
		&:hover{
			color: $primary-col;
		}
	}
}
.phone-number{
	float: left;
	width: auto;
	font-family: $mainfont;
	font-weight: $main-semibold;
	font-size: $size-base;
	color: $lighter;
	margin-right: 30px;
	
	a{
		font-family: $mainfont;
		font-weight: $main-semibold;
		font-size: $size-base;
		color: $lighter;
		
		&:hover{
			color: $primary-col;
		}
	}
	
}
.social-icons{
	float: left;
	width: auto;
	margin:2px 26px 0 0;
	
	ul{
		float: left;
		width: auto;
		
		li{
			float: left;
			margin: 0 5px;
			@include transit; 
			
			i{
				font-size: 20px;
    			line-height: 18px;
				
				&:hover{
					color: $primary-col; 
				}
			}
		}
	}
}
.language-switcher{
	float: right;
	width: 45px;
	position: relative;
	margin-top: 2px;
	
	a.lang{
		float: left;
    	padding-right: 20px;
    	width: 100%;
		position: relative;
		font-size: 13px;
		
		&:after{
			position: absolute;
			right: 8px;
			top: 4px;
			content: "\f0d7";
			font-family: 'FontAwesome';
			font-size: 10px;
			color: $lighter;
		}
	}
	
	ul{
		float: right;
		width: 50px;
		background: $lighter;
		position: absolute;
		top: 20px;
		z-index: 10;
		
		li{
			float: left;
			width: 100%;
			list-style: none;
			padding: 6px 10px;
			
			a{
				display: table;
				
				img{
					display: table-cell;
				}
				span{
					padding-left: 11px;
					display: table-cell;
				}
			}			
			&:hover span{
				color: $primary-col;
			}
		}
	}
}

.header-middle{
	float: left;
	width: 100%;
}
.logo{
	float: left;
	width: auto;
	margin-top: 8px;
}
.main-menu{
	float: right;
	width: auto;
	padding: 35px 0 6px 0;
	
	ul{
		float: right;
		width: auto;
		
		li{
			float: left;
			padding: 0 6px;
			position: relative;
			
			&:hover ul{
				display: block;
			}
			
			a{
				font-size: $size-menu;
				color: $txt-col;
				font-family: $mainfont;
				font-weight: 600;
				padding: 13px 14px 25px;
				float: left;
				
				&:hover{
					color: $primary-col;
				}
			}
			
			ul{
				display: none;
				position: absolute;
				left: 0;
				top: 47px;
				background: $lighter;
				width: 250px;
				z-index: 1000;
				margin: 0;
				padding: 7px 0 19px;
				/*-webkit-box-shadow: 0 2px 1px 1px #bababa;
				-moz-box-shadow: 0 2px 1px 1px #bababa;
				box-shadow: 0 2px 1px 1px #bababa;*/
				-webkit-box-shadow: 0px 6px 6px 0px rgba(0,0,0,0.3);
-moz-box-shadow: 0px 6px 6px 0px rgba(0,0,0,0.3);
box-shadow: 0px 6px 6px 0px rgba(0,0,0,0.3);
				
				li{
					width: 100%;
					padding:6px 23px;
					
					a{
						width: 100%;
						padding: 0;
					}
				}
			}
			
			&.active-nav a{
				color: $primary-col;
			}
			
			.submenu-arrow{
				width: auto;
				float: right;
				position: relative;
				
				&:after{
					position: absolute;
					right: 0;
					top: 21px;
					content: "\f0d7";
					font-family: 'FontAwesome';
					font-size: 10px;
					color: $darker;
				}
			}
		}
	}
}




.title-bar{display: none;}

.map-container .property-content .primary-button{
	width: 175px;
}
.gm-style-iw{
	width: 475px!important;
}





.header-middle .about-rentablanca{color: $about-text;width: 270px;margin: 35px 0 0 30px;}
	.header-middle .about-rentablanca span{color: $about-text;}





/*===============================================
    Media queries
==================================================*/

@include breakpoint(xlarge only) {
      
}
@media only screen and (max-width: 1400px) {//0-1400
    
}
@media only screen and (min-width: 992px) and (max-width: 1169px) {  
    .main-menu ul li a{font-size: 14px;padding: 13px 12px;}
	.main-menu ul li{padding: 0 2px;}
	.main-menu ul li ul li a{font-size: 14px;}
    .main-menu ul li .submenu-arrow::after {top: 20px;}
 	.email{margin-right: 46px;}
} 
@media only screen and (min-width: 992px) {
	
	
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
   .email{margin-right: 11px;}
	.main-menu ul li{padding: 0 2px;}
	
	.title-bar{display: block;}
	.title-bar-right {display: block; }   
    .off-canvas.is-open {transform: translate(-300px, 0);} 
    .js-off-canvas-overlay.is-visible {opacity: 1;visibility: visible;z-index: 1000; cursor: pointer;background: rgba(0,0,0,0.8);}
	
	.offcan-outer .main-menu{width: 100%;padding: 21px 0 6px;}
	.offcan-outer .main-menu ul li{width: 100%;padding: 0;text-align: center;border-bottom: 1px solid $border-col;}
	.offcan-outer .header-top{padding: 14px 25px 14px;}
	.offcan-outer .main-menu ul li:first-child{border-top: 1px solid $border-col;}
	.offcan-outer .main-menu ul li:first-child a{padding: 14px 5px 14px;}
	.offcan-outer .main-menu ul li a{width: 100%;padding: 11px 5px 13px;}
	.offcan-outer .logo{width: 100%;text-align: center;padding: 15px 0 0;}
	.offcan-outer .main-menu ul li .submenu-arrow::after{top: 14px;right: 0; left: 0;}
	.offcan-outer .main-menu ul li .submenu-arrow{height: 39px;position: absolute;right: 0;top: 0;background: $menu-bg;cursor: pointer;width: 33px;}
	.offcan-outer .main-menu ul li:hover ul{display: none;}
	.offcan-outer .main-menu ul li ul{position: relative;width: 100%;top: 0;box-shadow: none;padding: 0;background: $menu-bg;}
	.offcan-outer .main-menu ul li ul li{border-top: 1px solid $border-col;border-bottom: none;}
	.offcan-outer .main-menu ul li ul li a{padding: 11px 5px 13px;font-size: $size-base;}
	.offcan-outer .main-menu ul li ul li:first-child a{padding: 11px 5px 13px;}
	.header-middle .about-rentablanca{line-height: 24px;font-size: $size-base;}
	
	
	.title-bar{background: $lighter;padding: 0;float: right;width: 80px;}
	.title-bar-right .menu-icon span{font-size: $size-menu;font-weight: 700;color:$txt-col;text-transform: uppercase;float: left;margin: 3px 0 0 2px;}
	.title-bar-right .menu-icon{background: $lighter;width: 90px;margin: 56px 4px 0 0;height: 30px;}
	.title-bar-right .menu-icon img{width: 30px;height: 24px;float: left;}
	
	.menu-icon::after{display: none;}
	.header-middle .logo{margin-top: 16px;}
	.header-middle{padding-bottom: 14px;}
	.top-right{width: 100%;}
	.email{margin-right: 6%;}
	.phone-number{margin-right: 28%;}
	.header-middle .main-menu{display: none;}
	.social-icons{margin: 2px 9px 0 0;}
	
	
}

@media only screen and (max-width: 767px) {
  	.title-bar-right {display: block; }   
    .off-canvas.is-open {transform: translate(-300px, 0);} 
    .js-off-canvas-overlay.is-visible {opacity: 1;visibility: visible;z-index: 1000; cursor: pointer;background: rgba(0,0,0,0.8);}
	
	.top-right{width: 100%;}
	.social-icons{ margin: 0 26px 0 0;}
	.header-top .email{display: none;}
	
	
	.offcan-outer .main-menu{width: 100%;padding: 21px 0 6px;}
	.offcan-outer .main-menu ul li{width: 100%;padding: 0;text-align: center;border-bottom: 1px solid $border-col;}
	.offcan-outer .header-top{padding: 14px 25px 14px;}
	.offcan-outer .main-menu ul li:first-child{border-top: 1px solid $border-col;}
	.offcan-outer .main-menu ul li:first-child a{padding: 14px 5px 14px;}
	.offcan-outer .main-menu ul li a{width: 100%;padding: 11px 5px 13px;}
	.offcan-outer .logo{width: 100%;text-align: center;padding: 15px 0 0;}
	.offcan-outer .main-menu ul li .submenu-arrow::after{top: 14px;right: 0; left: 0;}
	.offcan-outer .main-menu ul li .submenu-arrow{height: 39px;position: absolute;right: 0;top: 0;background: $menu-bg;cursor: pointer;width: 33px;}
	.offcan-outer .main-menu ul li:hover ul{display: none;}
	.offcan-outer .main-menu ul li ul{position: relative;width: 100%;top: 0;box-shadow: none;padding: 0;background: $menu-bg;}
	.offcan-outer .main-menu ul li ul li{border-top: 1px solid $border-col;border-bottom: none;}
	.offcan-outer .main-menu ul li ul li a{padding: 11px 5px 13px;font-size: $size-base;}
	.offcan-outer .main-menu ul li ul li:first-child a{padding: 11px 5px 13px;}
	.header-middle .about-rentablanca{line-height: 24px;font-size: $size-base;}
	.title-bar{background: $lighter;padding: 0;float: right;width: 80px;}
	.title-bar-right .menu-icon span{font-size: $size-menu;font-weight: 700;color:$txt-col;text-transform: uppercase;float: left;margin: 3px 0 0 2px;}
	.title-bar-right .menu-icon{background: $lighter;width: 90px;margin: 44px 4px 0 0;height: 30px;}
	.title-bar-right .menu-icon img{width: 30px;height: 24px;float: left;}
	.header-middle .about-rentablanca{color: $about-text;width: 270px;margin: 35px 0 0 30px;}
	.header-middle .about-rentablanca span{color: $about-text;}
	.menu-icon::after{display: none;}
	.header-middle .logo{margin-top: 16px;}
	.header-middle{padding-bottom: 14px;}
	.top-right{width: 100%;}
	.email{margin-right: 6%;}
	.phone-number{margin-right: 29%;}
	.title-bar{display: block;}
	.header-middle .main-menu{display: none;}
}
@media only screen and (max-width: 479px) {
	.phone-number{margin-right: 5%;font-size: 13px;}
	.phone-number a{font-size: 13px;}
	.social-icons{margin: 0 12px 0 0;}
}