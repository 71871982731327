/*===============================================
    Home Page 
==================================================*/
.content-container{
	float: left;
	width: 100%;
}
.home-banner{
	float: left;
	width: 100%;
	position: relative;
}
.home-banner .slick-track{
	height: 542px;
}
.home-slide{
	height: 542px;
	position: relative;
	
	&:before{
		position: absolute;
		left: 0;
		bottom: 0;
		content: "";
		width: 100%;
		height: 100%;
		background: url(../images/slide_shadow.png) repeat-x left top;
	}
}
.banner-content{
	float: left;
	width: 100%;
	text-align: center;
	position: absolute;
	right: 0;	
	left: 50%;
 	margin: auto;
 	top: 39.3%;
	 -webkit-transform: translate(-50%, -50%);
	 -moz-transform: translate(-50%, -50%);
	 transform: translate(-50%, -50%);
	
	h1 {
		color: #fff;
	}
	
	span{
		font-size: $size-tab;
		color: $lighter;
		font-weight: 600;
		text-transform: uppercase;
		float: left;
		width: 100%;
		margin-top: 15px;
	}
}

/*======================================
	Search Box
========================================*/
.search-outer{
	margin: 0 auto;
    max-width: 920px;
    padding: 0;
    width: 100%;
}
.search-content{
	position: absolute;
	bottom: 28px;
	float: left;
	width: 100%;
    z-index: 9;
}
.search-content .tabs{
	float: left;
	width: auto;
	
	border: none;
}
.search-content .tabs li{
	width: 160px;
	text-align: center;
}
.search-content .tabs li.is-active a{
	background: $lighter;
	color: $txt-col;
	border: 1px solid $menu-bg;	
}
.search-content .tabs li a{
	font-size: $size-tab;
	text-transform: uppercase;
	color: $lighter;
	font-weight: 600;
	background: $txt-col;
	padding: 17px 30px 15px;
	border: 1px solid $txt-col;	
}
.search-content .tabs-content{
	border: none;
	float: left;
	width: 100%;
	background: none;
	
	.tabs-panel{
		float: left;
		width: 100%;
		padding: 0;
	}
}
.single-field{
	float: left;
	width: 100%;
	max-width: 220px;
	margin-right: 17px;
	padding-bottom: 9px;
	
	&.date-select{
		position: relative;
		
		.ui-datepicker-trigger{
			width: 33px;
			height: 33px;
			position: absolute;
			right: 2px;
			top: 2px;
			cursor: pointer;
		}
	}
}
.ui-datepicker-calendar th span{
	color: $dark-txt;
}
.entry-fields{
	float: left;
	width: 80.2%;
}
.search-result{
	float: left;
	width: 100%;
	text-align: center;
	color: $tab-content;
	font-size: $size-base;
	font-weight: 400;
	margin: 13px 0 0;
	
	b{
		color: $tab-content;
	}
}
.tabs-panel form{
	float: left;
	width: 100%;	
}
.result-field{
	float: right;
	width: 175px;
	
	.primary-button{
		width:175px;
	}
}

b{
	font-weight: 700;
}
.fields-top{
	float: left;
	width: 100%;
	background: $lighter;
	padding: 17px 15px 9px 18px;
}
.fields-bottom{
	float: left;
	width: 100%;
	background: rgba(0,0,0,0.5);
	padding: 14px 0 15px;
}


.icheckbox{
    float: left;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    width: 17px;
    height: 17px;
    border: none;
    cursor: pointer;
	background: $lighter;
	border-radius: 3px;	
}
.search-outer  .icheckbox.checked {
	
	&:before{
		position: absolute;
		left: 0;
		top: 0;
		content: "";
		background: url(../images/tick.svg) no-repeat left top;
		width: 16px;
		height: 17px;
	}
}
.icheckbox-outer{
	float: left;
	width: 100%;
	text-align: center;
}
.icheckbox-outer li{
	display: inline-block;
	width: 142px;
	margin: 0 10px;
	vertical-align: middle;
	
	label{
		float: left;
		padding-left: 15px;
		text-align: left;
		line-height: 19px;
		cursor: pointer;
	}
}

/*======================================
	Select Box
========================================*/
 
.sbHolder{   
	font-family: $mainfont; 
	font-size: $size-base;
	color: $tab-content; 
	height: 36px;
	position: relative;
	width: 100%;
	background: $input-bg;
	border: 1px solid $input-border;
	border-right: none;
	border-bottom: none;
	padding: 6px 14px;
}
 .sbSelector{
	display: block;
	height: 36px;
	left: 0;
	line-height: 12px;
	padding: 11px 14px;
	outline: none;
	overflow: hidden;
	position: absolute; 
	top: 0;
	width:  85%;
    text-align: left; 
	font-family: $mainfont; 
	font-size: $size-base;
	color: $tab-content; 
}
.sbSelector:link, .sbSelector:visited, .sbSelector:hover{ 
	outline: none;
	text-decoration: none;
}
.sbToggle{	 
	display: block;
	height: 22px;
	outline: none;
	position: absolute;
	right: 7px;
	top: 6px;
	width: 22px; 
	background: $primary-col;
	border-radius:3px;
	
	&:before{
		position: absolute;
		right: 8px;
		top: 6px;
		content: "\f0d7";
		font-family: 'FontAwesome';
		font-size: 10px;
		color: $lighter;
	}
}
 .sbOptions{  
	list-style: none;
	left: -1px;
	margin: 0;
	padding: 0;
	position: absolute;
	top: 35px!important;  
	z-index: 1;
	overflow: hidden;
	overflow-y: auto;
	float: left!important; 
	width: 100%;
	border: 1px solid $input-border;
	background: $input-bg;
    z-index: 21; 
}
.sbOptions li{
	float: left!important;
	width: 100%!important;
    border: none!important;
    background: $input-bg;
}
.sbOptions a{ 
	display: block;
	outline: none;
	padding: 6px 17px;
    text-align: left; 
    float: left!important;
	width: 100%;
	background: $input-bg;  
	font-family: $mainfont; 
	font-size: $size-base;
	color: $tab-content; 
}
.sbOptions a:link, .sbOptions a:visited{ 
	text-decoration: none;
}
.sbOptions li a:hover,
.sbOptions li a:focus, 
.sbOptions a.sbFocus{ 
	background: $primary-col;
	color: $lighter;
}
.sbOptions li.last a{
	border-bottom: none;
}
.sbOptions .sbDisabled{  
	display: block;
	padding: 0px;
}
.sbOptions .sbGroup{  
	display: block;
	font-weight: bold;
	padding: 0px;
}
.sbOptions .sbSub{
	padding-left: 17px;
}


/*======================================
	Datepicker
========================================*/
.datepicker {
    display: none;
    position: absolute;
    padding: 4px;
    margin-top: 1px;
    direction: ltr;
}
.datepicker.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    float: left;
    display: none;
    min-width: 160px;
    list-style: none;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    *border-right-width: 2px;
    *border-bottom-width: 2px;
    color: #333;
    font-size: 13px;
    line-height: 18px;
}
.datepicker.dropdown-menu th {
    padding: 4px 5px;
}
.datepicker.dropdown-menu td {
    padding: 4px 5px;
}
.datepicker table {
    border: 0;
    margin: 0;
    width: auto;
}
.datepicker table tr td span {
    display: block;
    width: 23%;
    height: 54px;
    line-height: 54px;
    float: left;
    margin: 1%;
    cursor: pointer;
}
.datepicker td {
    text-align: center;
    width: 20px;
    height: 20px;
    border: 0;
    font-size: 12px;
    padding: 4px 8px;
    background: #fff;
    cursor: pointer;
	color: $dark-txt;
	font-family: $mainfont;
	font-weight: 400;
}
.datepicker td.active.day,
.datepicker td.active.year {
    background: $primary-col;
	color: $lighter;
	font-weight: 600;
}
.datepicker td.old,
.datepicker td.new {
    color: #999;
}
.datepicker td span.active {
    background: $primary-col;
}
.datepicker td.day.disabled {
    color: #eee;
}
.datepicker td span.month.disabled,
.datepicker td span.year.disabled {
    color: #eee;
}
.datepicker th {
    text-align: center;
    width: 20px;
    height: 20px;
    border: 0;
    font-size: 12px;
    padding: 4px 8px;
    background: #fff;
    cursor: pointer;
	color: $dark-txt;
	font-family: $mainfont;
	font-weight: 400;
}
.datepicker th.active.day,
.datepicker th.active.year {
    background: #2ba6cb;
}
.datepicker th.date-switch {
    width: 145px;
}
.datepicker th span.active {
    background: #2ba6cb;
}
.datepicker .cw {
    font-size: 10px;
    width: 12px;
    padding: 0 2px 0 5px;
    vertical-align: middle;
}
.datepicker.days div.datepicker-days {
    display: block;
}
.datepicker.months div.datepicker-months {
    display: block;
}
.datepicker.years div.datepicker-years {
    display: block;
}
.datepicker thead tr:first-child th {
    cursor: pointer;
}
.datepicker thead tr:first-child th.cw {
    cursor: default;
    background-color: transparent;
}
.datepicker tfoot tr:first-child th {
    cursor: pointer;
}
.datepicker-inline {
    width: 220px;
}
.datepicker-rtl {
    direction: rtl;
}
.datepicker-rtl table tr td span {
    float: right;
}
.datepicker-dropdown {
    top: 0;
    left: 0;
}
.datepicker-dropdown:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-bottom-color: 1px solid rgba(0, 0, 0, 0.2);
    position: absolute;
    top: -7px;
    left: 6px;
}
.datepicker-dropdown:after {
    content: '';
    display: inline-block;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    position: absolute;
    top: -6px;
    left: 7px;
}
.datepicker > div,
.datepicker-dropdown::before,
.datepicker-dropdown::after {
    display: none;
}
.datepicker-close {
    position: absolute;
    top: -30px;
    right: 0;
    width: 30px!important;
	line-height: 24px;
    height: 30px;
    padding: 0;
    display: none;
}
.table-striped .datepicker table tr td,
.table-striped .datepicker table tr th {
    background-color: transparent;
}



/*======================================
	Image Blocks
========================================*/
.image-links{
	float: left;
	width: 100%;
	background: $border-col;
	padding: 77px 0 80px;
	overflow: hidden;
	
	ul{
		float: left;
		width: 100%;
		text-align: center;
		overflow: hidden;
		
		li{
			display: inline-block;
			width: 223px;
			height: 100%;
			border: 11px solid transparent;
			@include border-radius(100%);		
			margin: 0 22px;
			overflow: hidden;
			
			a{
				float: left;
				width: 100%;
				height: 100%;
				@include border-radius(100%);
				overflow: hidden;
				position: relative;
				border: 11px solid $input-bg;
				z-index: 1;
				
				img{
					float: left;
					@include border-radius(100%);		
					@include transition(all, 0.5s, ease-in-out);
					z-index: -10;
				}
				
				span{
					color: $lighter;
					font-size: $size-large;
					text-transform: capitalize;
					font-weight: 600;
					float: left;
					width: 100%;
					text-align: center;
					position: absolute;
					right: 0;	
					left: 50%;
					margin: auto;
					top: 50%;
					z-index: 1;
					
					-moz-transform: translate(-50%, -50%);
					-webkit-transform: translate(-50%, -50%);
					-ms-transform: translate(-50%, -50%);
					-o-transform: translate(-50%, -50%);
					transform: translate(-50%, -50%);	
				}
				
				&:before{
					position: absolute;
					left: 0;
					top: 0;
					content: "";
					width: 100%;
					height: 100%;
					background: rgba(61,66,74,0.5);
					z-index: 1;
                    @include border-radius(100%);
				}
			}
		}
	}
}
.image-links ul li:hover img{		
	-moz-transform: scale(1.2);
	-webkit-transform: scale(1.2);
	-ms-transform: scale(1.2);
	-o-transform: scale(1.2);
	transform: scale(1.2);
}





/*======================================
	About Us
========================================*/
.about-block{
	float: left;
	width: 100%;
	position: relative;
}
.about-thumnail{
	float: left;
	width: 44%;	
	position: absolute;
}
.about-thumb1{
	float: left;
	width: 100%;
}
.thumb-single{
	float: left;
	width: 100%;
	height: 265px;
}
.thumb-single.slick-slide{
	float: left;
	width: 100%;
	height: 561px;
}
.disable-slider{
	display: none;
}
.about-thumb2{
	display: none;
}
.about-content{
	float: right;
	width: 100%;
	padding: 39px 0 73px 125px;
	
	h3{
		color: $tab-content;
		position: relative;
		padding-bottom: 23px;
		margin-bottom: 27px;
		
		&:before{
			position: absolute;
			left: 0;
			bottom: 0;
			content: "";
			width: 30px;
			height: 2px;
			background: $primary-col;
		}
	}
	
	p{
		strong{
			color:$tab-content;
		}
	}
}


/*======================================
	Why Choose
========================================*/
.why-choose-block{
	float: left;
	width: 100%;
	margin-top: -1px;
}
.why-choose-content{
	float: left;
	width: 56.8%;
	background: $secondary-col;
	position: relative;
	padding:0 10% 0 0;
	
	&:before{
		position: absolute;
		right: 100%;
		bottom: 0;
		content: "";
		width: 600%;
		height: 100%;
		background: $secondary-col;
	}
	
	h3{
		color: $lighter;
		position: relative;
		padding-bottom: 16px;
		margin-bottom: 28px;
		text-transform: capitalize;
		padding:71px 0 30px 0;
		
		&:before{
			position: absolute;
			left: 0;
			bottom: 0;
			content: "";
			width: 30px;
			height: 2px;
			background: $lighter;
		}
	}
	
	p{
		color: $lighter;
	}
}
.contact-info{
	float: left;
	width: 32.5%;
	background: $content-bg;
	padding: 0 62px 0;
	position: relative;
	
	
	&:before{
		position: absolute;
		left: 100%;
		bottom: 0;
		content: "";
		width: 600%;
		height: 100%;
		background: $content-bg;
	}
	
	.info-single{
		float: left;
		width: 100%;
		margin-bottom: 29px;
		
		span{
			font-size: $size-base;
			color: $lighter;
			font-weight: 400;
			float: left;
			width: 100%;
			position: relative;
			padding:0 0 5px 33px;
			
		}
		
		a{
			font-size: $size-base;
			color: $lighter;
			font-weight: 700;
			float: left;
			width: 100%;
			padding:0 0 0 33px;
			
			&:hover{
				color:$primary-col;
			}
		}
		
		address{
			font-size: $size-base;
			color: $lighter;
			font-weight: 700;
			float: left;
			width: 100%;
			padding:0 0 4px 31px;
			line-height: 25px;
		}
		
		&.phone-info{
			padding-top: 84px;
				
			span{
				&:before{
					position: absolute;
					left: 0;
					top: -3px;
					content: "\f095";
					font-family: 'FontAwesome';
					font-size: 25px;
					color: $lighter;
				}
			}
		}
		
		&.mail-info{
			
			span{
				&:before{
					position: absolute;
					left: 0;
					top: 0;
					content: "\f0e0";
					font-family: 'FontAwesome';
					font-size: 17px;
					color: $lighter;
				}
			}
		}
		
		&.address-info{
			margin-bottom: 108px;
				
			span{
				&:before{
					position: absolute;
					left: 0;
					top: 1px;
					content: "\f041";
					font-family: 'FontAwesome';
					font-size: 25px;
					color: $lighter;
				}
			}
		}
	}
	
	.why-choose-thumb{
		float: right;
		width: 150%;
		position: absolute;
		left: 100%;
		top: 0;
		height: 444px;
		
		img{
			float: left;
		}
	}
}


/*===============================================
    Featured Properties
==================================================*/
.featured-container{
	float: left;
	width: 100%;
	padding: 31px 0 20px;
	text-align: center;
	
	h3{
		color:$tab-content; 
		text-align: center;
		float: left;
		width: 100%;
		padding-bottom: 23px;
		margin-bottom: 38px;
		position: relative;
		
		&:before{
			position: absolute;
			left: 0;
			right: 0;
			margin: 0 auto;
			bottom: 0;
			content: "";
			width: 30px;
			height: 2px;
			background: $primary-col;
		}
	}
	
	.primary-button{
		float: none;
		display: inline-block;
		margin: 40px 15px 0;
		padding: 8px 15px 10px 16px;
		
	}
	.secondary-button{
		float: none;
		display: inline-block;
		margin: 40px 15px 0;
		padding: 8px 13px 10px 14px;
	}
}
.map-container {

	.primary-button{

		margin:0;
		
	}
}
.block-single{
	float: left;
	width: 100%;
}
.property-image{
	float: left;
	width: 100%;
	position: relative;
	@include transition(all,0.3s,ease-in-out);
	height: 182px;
	
	.status-btn{
		width: auto;
		font-size: $size-link;
		color: $lighter;
		text-transform: uppercase;
		font-weight: 600;
		padding: 4px 9px 5px 10px;
		text-align: center;
		float: left;
		position: absolute;
		@include transition(all,0.6s,ease-in-out);
		
		&.left-position{
			left: 0;
			bottom: 10px;
		}
		
		&.right-position{
			right: 0;
			top: 0;
		}
		
		&.sale-btn{
			background: $secondary-col;
		}
		
		&.rent-btn{
			background: $primary-col;
		}
		
		&.reduced-btn{
			background: $bg1;
		}
		
		&.reserved-btn{
			background: $bg2;
		}
		
		&.new-btn{
			background: $bg3;
		}
		
		&.sold-btn{
			background: $bg4;
		}
	}
		
	img{
		float: left;
	}

	a{
		float: left;
		width: 100%;			
		@include transition(all,0.3s,ease-in-out);

		.featured-overlay-outer{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			background: rgba(0,0,0,0.5);
			height: 100%;
			@include transition(opacity,0.6s,ease-in-out);
			float: left;
			opacity: 0;

			.featured-overlay{
				color: $lighter;
				float: left;
				left: 50%;
				margin: auto;
				position: absolute;
				text-align: center;
				top: 50%;
				-webkit-transform: translate(-50%, -50%);
				-moz-transform: translate(-50%, -50%);
				transform: translate(-50%, -50%);
				width: 100%;
				@include transition(all,0.3s,ease-in-out);
				
				.overlay-plus{
					font-size: 35px;
				}
				
				span{
					float: left;
					font-size: $size-base;
					width: 100%;
					font-weight: 700;
					text-transform: uppercase;
					text-align: center;
					@include transition(all,0.3s,ease-in-out);
				}
			}
		}			
	} 
}
.property-image a:hover .status-btn{
	opacity: 0;
}
.property-image a:hover .featured-overlay-outer {
    opacity: 1;
}

.property-content{
	float: left;
	width: 100%;
	background: $input-bg;
	padding: 12px 14px 16px 17px;
	text-align: left;
}
.property-name{
	float: left;
	width: 100%;
	display: table;
	height: 35px;
	margin-bottom: 13px;
	
	.proprty-name-inner{
		color: $dark-txt;
		font-weight: 700;
		display: table-cell;
		vertical-align: middle;
		width: 100%;
		height: 100%;
		font-size: $size-base;
	}
}
.property-type{
	float: left;
	width: 100%;
	margin-bottom: 10px;
	
	ul{
		float: left;
		width: 100%;
		
		li{
			float: left;
			padding-left: 14px;
			text-align: left;
			
			&:first-child{
				padding-left: 0;
			}
			
			.type-icon{
				float: left;
			}
			
			.type-name{
				color: $tab-content;
				font-size: $size-base;
				font-weight: 400;
				float: left;
				padding-left: 5px;
				line-height: 14px;
				
				sup{
					color: $tab-content;
					left: -3px;
    				top: -4px;
					font-size: $size-extra-small;
				}
			}
		}
	}
}
.property-amount{
	float: left;
	width: 100%;
	
	.amount-label{
		float: left;
		text-transform: capitalize;
		color: $tab-content;
		font-size: $size-base;
		font-weight: 400;
		margin-top: 3px;
	}
	
	.amount-value{
		float: right;
		color: $tab-content;
		font-size: $size-tab;
		font-weight: 400;
		
		b{
			color: $tab-content;
			font-size: $size-tab;
			font-weight: 700;
		}
	}
}


/*===============================================
   Map Block
==================================================*/
.map-container{
	float: left;
	width: 100%;
	padding: 40px 0 0;
}
#map {
  height: 488px;
  width: 100%;
}
.pointer-outer{
	float: left;
	width: 488px;
	background: $lighter;
	
	.property-image{
		float: left;
		width: 270px;
	}
	
	.property-content{
		float: left;
		width: 198px;
		background: $lighter;
		padding: 10px 14px 4px 17px;
		
		.property-amount .amount-value{
			float: left;
			margin-bottom: 21px;
			margin-top: 2px;
		}
		
		.property-type{
			margin-bottom: 10px;
			margin-top: 3px;
		}
	}
}


.about-block .about-thumb2{height: 272px;}






@media screen and (-webkit-min-device-pixel-ratio:0) { 
	.contact-info .why-choose-thumb{height: 439px;}
}






/*===============================================
    Media queries
==================================================*/

@include breakpoint(xlarge only) {
      
}
@media only screen and (max-width: 1400px) {//0-1400
    
}
@media only screen and (min-width: 992px) and (max-width: 1169px) {  
    .about-content{padding: 39px 20px 2px 125px;}
	
	.property-content{padding: 12px 9px 16px;}
	.property-type ul li{padding-left: 8px;}
	.property-type ul li .type-name{font-size: 12px;}
	.why-choose-content{padding: 0 8% 0 20px;width: 50%;}
	
 
} 
@media only screen and (min-width: 992px) {
	
	
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.search-content .medium-10.medium-centered.large-12.large-centered.columns{padding: 0;}
	.banner-content{top: 30%;}
	.result-field .primary-button{max-width: 100px;min-width: 100px;}
	.result-field{float: right;width: 14%;}
	.entry-fields{width: 86%;}
	.single-field{max-width: 190px;margin-right: 8px;}
	.sbSelector{font-size: 13px;}
	
	.image-links{display: none;}
	.about-thumb1{height: 265px;}
	.about-thumb2{display: block;float: left;width: 100%}
	.about-thumb2 img{float: left;}
	.about-thumnail{width: 50%;}
	.about-content{padding: 34px 0 0 15px;}
	.about-content p{ line-height: 20px;}
	.why-choose-content{width: 50%;padding:0 42px 0 15px;}
	.why-choose-content h3{padding: 70px 0 24px;margin-bottom: 30px;font-size: 22px;}
	.why-choose-content p{line-height: 21px;}
	.contact-info{width: 50%;padding: 0 62px 0 82px;}
	.featured-container{padding: 31px 12.5% 0;}
	.property-content{padding: 12px 14px 16px;}
	.small-up-1 > .column{margin-bottom: 20px;}
	.contact-info .why-choose-thumb{display: none;}
	.property-type ul li{padding-left: 11px;}
	.disable-slider{display: block;}
	
}
@media only screen and (max-width: 767px) {
  	.banner-content h1{font-size: 32px;}
	.search-content .medium-10.medium-centered.large-12.large-centered.columns{padding: 0;}
	.search-content .tabs{width: 100%;}
	.search-content .tabs li{width: 50%;}
	.entry-fields{width: 100%;margin-bottom: 9px;text-align: center;}
	.single-field{max-width: 100%;margin-right: 10px;display: inline-block;float: none;width: 48%;}
	.fields-top{padding: 30px 30px 18px;}
	.result-field{width: 100%;}
	.result-field .primary-button{float: right;width: 48.6%;}
	.search-content .columns{padding: 0!important;}
	.single-field:nth-child(2n){margin-right: 0;}
	.search-result{margin: 10px 0 0 0;width: 48%;}
	.search-content{bottom: 0;position: relative;}
	.home-slide{height: 287px!important;}
	.home-banner .slick-track{height: 287px!important;}
	.banner-content{top: 204px;}
	.banner-content span{margin-top: 17px;}
	.home-slide::before{background: rgba(0,0,0,0.5);}
	.search-content .tabs li a{padding: 15px 30px 19px;}
	.search-content .tabs li a{border:none;}
	.search-content .tabs li.is-active a{border:none;}
	
	
	.image-links{display: none;}
	.about-block{display: none;}
	.why-choose-content{display: none;}
	.contact-info{width: 100%;}
	.contact-info::after{
		position: absolute;
		right: 100%;
		bottom: 0;
		content: "";
		width: 600%;
		height: 100%;
		background: $content-bg;
	}
	.contact-info .why-choose-thumb{display: none;padding: 0 62px 0 75px;}
	.block-single{padding: 0 12.6% 30px;}
	.property-type ul li{padding-left: 29px;}
	.featured-container .secondary-button{margin: 4px 0 0;min-width: 338px;}
	.featured-container .primary-button{margin: 4px 0 0;min-width: 338px;}
	.featured-container{padding: 31px 0 0;}
	.map-container{display: none;}
	.secondary-button.show-for-small-only{margin-bottom: 34px;}
	.property-image{height: 229px;}
	.property-name .proprty-name-inner{font-size: 17px;}
	.property-amount .amount-label{font-size: 17px;}
	.property-amount .amount-value{font-size: 20px;}
	.property-type ul li .type-name{font-size: 17px;}
	.property-image .status-btn{font-size: 16px;}
	
	.disable-slider{display: block;}
    .contact-info .info-single.address-info { margin-bottom: 35px;}
    .contact-info .info-single.phone-info { padding-top: 40px;}


}
@media only screen and (max-width: 479px) {
	.single-field{max-width: 100%;width: 100%;}
	.result-field .primary-button{width: 50%;}
	.search-result{margin: 10px 0 0 0;width: 50%;}
	.icheckbox-outer li{margin: 0 2px;width: 96px;}
	.icheckbox-outer li label{padding-left: 5px;font-size: 11px;overflow-wrap: break-word;width: auto; line-height: 15px;}
	
	.contact-info{padding: 0 25px;}
	.block-single{padding: 0 0 30px;}
	.featured-container .primary-button{min-width: 285px;}
	.property-type ul li .type-name{ font-size: 14px;}
	.property-name .proprty-name-inner{font-size: 15px;}
	.property-image .status-btn{font-size: 14px;}
	.property-amount .amount-label{font-size: 15px;}
	.property-amount .amount-value{font-size: 17px;}
	.property-type ul li{padding-left: 21px;}
	
	.disable-slider{display: block;}
}