// Variables
// -------------------------- 
@import  "_fonts"; 

// Colors and backgrounds
// --------------------------

$darker:                      	#000000!default;
$dark-txt:                      lighten($darker, 2)     !default; //#050505;
$dark-txt:                      lighten($darker, 5)     !default; //#050505;
$semi-dark:                     lighten($darker, 13)    !default; //#222222;
$bullet-col:                    lighten($darker, 17)    !default; //#2b2b2b;
$calender-txt:                  lighten($darker, 18)    !default; //#2d2d2d;
$tab-content:                   lighten($darker, 22)    !default; //#373737 
$button-slider:                 lighten($darker, 42)    !default; //#6a6a6a 
$tab-txt:                       lighten($darker, 20)    !default; //#333333
$col-para:                      lighten($darker, 25)    !default; //#404040  
$label-col:                     lighten($darker, 33)    !default; //#555555  
$calender-border:               lighten($darker, 84)    !default; //#d5d5d5  
$menu-shadow:                   lighten($darker, 90)    !default; //#e5e5e5  
$list-border:                   lighten($darker, 91)    !default; //#e9e9e9  
$input-border:                  lighten($darker, 93)    !default; //#ededed  
$border-col:                    lighten($darker, 95)    !default; //#f2f2f2  
$menu-bg:	                    lighten($darker, 96)    !default; //#f6f6f6  
$input-bg:	                    lighten($darker, 98)    !default; //#fafafa  
$lighter:                      	lighten($darker, 100)   !default; //#ffffff



$primary-col:       	      	#fb6a19	!default; 
$secondary-col:                 #0096c9 !default;
$white-txt:                     #bafdff !default;
$txt-col:                       #3d424a !default; 
$light-col:                     #5f6165 !default; 
$bg1:                           #80b117 !default; 
$bg2:                           #eda200 !default; 
$bg3:                          	#00beff !default; 
$bg4:                          	#d92640 !default; 
$header-top-bg:                	#00afea !default; 
$about-text:                	#136d8b !default; 
$site-text:                 	#909cb0 !default; 
$content-bg:                 	#0075ac !default; 
$facebook-bg:                 	#3b5998 !default; 
$twitter-bg:                 	#1da1f2 !default; 
$linkedin-bg:                 	#0077b5 !default; 
$plus-bg:                 	    #ff6550 !default; 
$download-color:                #dc3900 !default; 
$block-color:                   #f1f1f1 !default; 
$border-col2:                   #e6e6e6 !default; 
$border-col3:                   #e4e2e2 !default; 
$border-col4:                   #49aed1 !default; 
$tick-col:	                    #387d25 !default; 
$calender-selected:	            #f0a868 !default; 

 
$blockoverlaycol:               rgba(41,58,146,0.48)!default; 

//== Typography
// --------------------------  
 

$mainfont:  	'Open Sans'; 
$subfont:  	    'PT Sans'; 
$italicfont:  	    'Lato', sans-serif;

$main-regular:     400;
$main-semibold:    600;
$main-bold:        700;
$sub-regular:      400;
$sub-italic:       400i;
$sub-bold:         700;

$size-base:    14px !default; 

$size-h1:            	ceil(($size-base * 2.571))   	!default;   // ~36px
$size-h2:            	ceil(($size-base * 2))    	    !default;   // ~28px
$size-h3:            	ceil(($size-base * 1.785))    	!default;   // ~25px
$size-h4:            	ceil(($size-base * 1.714))      !default;   // ~24px
$size-h5:            	ceil(($size-base * 1.571))    	!default;   // ~22px
$size-h6:            	ceil(($size-base * 1.285))    	!default;   // ~18px 


$size-small:            ceil(($size-base * 0.857)) 		!default;   // ~12px
$size-link:             ceil(($size-base * 0.928)) 		!default;   // ~13px
$size-menu:             ceil(($size-base * 1.071)) 		!default;   // ~15px
$size-tab:              ceil(($size-base * 1.142)) 		!default;   // ~16px
$size-block:            ceil(($size-base * 1.214)) 		!default;   // ~17px
$size-large:            ceil(($size-base * 1.428)) 		!default;   // ~20px
$size-extra-small:      ceil(($size-base * 0.714)) 		!default;   // ~10px

$rental-color: #fb6a19;
$rental-hover-color: #eda200;
$sale-color: #0096c9;
$sale-hover-color: #00beff;


 