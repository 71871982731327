.search-content {
    form {
        input[type="text"] {
            font-family: "Open Sans";
            font-size: 14px;
            color: #383838;
            height: 36px;
            position: relative;
            width: 100%;
            background: #fafafa;
            border: 1px solid #ededed;
            border-right: none;
            border-bottom: none;
            box-shadow: none;
            padding: 6px 14px;
            margin: 0;

            &:focus, &:hover {
                outline: none;
                border: 1px solid #8a8a8a;
                background-color: #fefefe;
                box-shadow: 0 0 5px #cacaca;
                transition: box-shadow 0.5s,border-color 0.25s ease-in-out;

                &::placeholder {
                    color: #fb6a19;
                }
            }

            &::placeholder {
                color: #383838;
            }
        }

        .icheckbox-outer {
            li {
                label {
                    color: #fff;
                }
            }
        }
    }
}

.tabs-content {
    form {
        .date-select {
            &:hover {
                cursor: pointer;
            }
        }
    }
}

.content-container {
    h1.page-title {
        color: #000;
        padding: 1em 0;
        margin-bottom: .5em;
    }

    .order-block {
        margin-bottom: 2em;

        span {
            color: #000;
            margin-bottom: 1em;
        }

        .selectbox-outer {
            margin: 1em 0;
        }
    }

    .pagination {
        text-align: center;
        margin-top: 2em;

        .arrow {
            &.unavailable {
                display: none;
            }
        }

        li {
            a {
                padding: 3px 16px;
            }
        }
    }
}

.details-block .details-single.alquiler {
    margin-top: 0;
}

.detail-tab {
    margin-top: 84px;

    .tabs-content {
        .enquiry-form {
            .captcha-block {
                .captcha-image {
                    float: none;
                    margin-bottom: 11px;

                    tbody {
                        td {
                            &:first-child {
                                background: #ededed;

                                img {
                                    height: 31px;
                                }
                            }

                            &:last-child {
                                background: #ededed !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

.property-image {
    .status-btn {
        &.rental-btn {
            background: $rental-color;
        }
    }
}

label.disabled {
    font-weight: bold;
}

.sale {
    span.primary-button {
        background-color: $sale-color;

        &:hover {
            background-color: $sale-hover-color;
        }
    }

    .detail-page-rate {
        .detail-rate-text {
            h6 {
                color: $sale-color;
            }
        }
    }

    .detail-rate-button {
        .primary-button {
            background: $sale-color;

            &:hover {
                background: $sale-hover-color;
            }
        }
    }

    .details-block {
        .primary-button {
            background: $sale-color;

            &:hover {
                background: $sale-hover-color;
            }
        }
    }

    .detail-tab {
        .tabs {
            li.is-active.tabs-title {
                a {
                    background: $sale-color;
                }
            }
        }

        .tabs-content {
            .enquiry-form {
                .primary-button {
                    background: $sale-color;
                }
            }
        }
    }
}

#panel1 {
    form {
        .sbToggle {
            background: $sale-color;
        }

        button {
            background: $sale-color;

            &:hover {
                background: $sale-hover-color;
            }
        }

        a {
            &:hover {
                color: $sale-hover-color;
            }
        }
    }
}

.sales-list {
    h1 {
        &:before {
            background-color: $sale-color;
        }
    }

    .pagination {
        .current {
            background: $sale-color;
            padding: 0;

            a {
                color: #fff;

                &:hover {
                    background: $sale-hover-color;
                }
            }
        }

        a {
            &:hover {
                background: $sale-hover-color;
                color: #fff;
            }
        }
    }
}

.rentals-list {
    .pagination {
        .current {
            background: $rental-color;
            padding: 0;

            a {
                color: #fff;

                &:hover {
                    background: $rental-hover-color;
                }
            }
        }

        a {
            &:hover {
                background: $rental-hover-color;
                color: #fff;
            }
        }
    }
}

#contactoForm, #carRentalForm {
    input[type='text'], textarea {
        &::placeholder {
            color: #545454;
        }
    }

    input[type='text'] {
        background: #ededed;
        color: #545454;
        text-transform: capitalize;
        border: none;
        font-size: 15px;
        box-shadow: none;
        height: 54px;
        padding: 5px 35px 5px 14px;
        margin: 0 0 11px;
    }

    textarea {
        background: #ededed;
        color: #545454;
        text-transform: capitalize;
        border: none;
        font-size: 15px;
        box-shadow: none;
        height: 148px;
        resize: none;
        padding: 22px 14px;
        margin: 0 0 11px;
    }

    input[type='submit'] {
        width: 100%;
        margin-top: 4px;
        height: 54px;
        padding: 9px 10px 10px;
        background: $rental-color;
        text-transform: uppercase;
        margin: 0 0 11px;

        &:hover {
            background: $rental-hover-color;
        }
    }

    .crypto {
        display: block;
        float: none;
        margin-bottom: 11px;

        tbody {
            td {
                &:first-child {
                    background: #ededed;

                    img {
                        height: 31px;
                    }
                }

                &:last-child {
                    background: #ededed !important;
                }
            }
        }
    }

    .field-one {
        position: relative;

        &:after {
            display: block;
            position: absolute;
            right: 32px;
            top: 24px;
            content: "";
            background: url(../images/required_icon.png) no-repeat left top;
            width: 6px;
            height: 6px;
        }
    }

    & > div > fieldset:nth-child(5) > div:nth-child(2),
    & > div > fieldset:nth-child(5) > div:nth-child(3) {
        &:after {
            right: 52px;
        }
    }

    .date-picker, .date-picker-array {
        position: relative;

        .calendar-icon {
            width: 33px;
            height: 33px;
            position: absolute;
            right: 1.5em;
            top: .55em;
            z-index: 99;
        }
    }

    .captcha-block {
        margin: 1.125rem 0;
        padding: 1.25rem;
    }
}

#carRentalForm {
     fieldset  {
          .time-picker {
               label, input[type="time"] {
                    float: left;
                    padding: 5px 35px 5px 14px;
                    font-size: 15px;
                    margin: 0 0 11px;
                    background: #ededed;
               }
               label {
                    width: 50%;
                    height: 54px;
                    font-size: 15px;
                    color: #545454;
                    line-height: 44px;
     
               }
               input[type="time"] {
                    width: 50%;
                    text-align: right;
                    color: #545454;
                    text-transform: capitalize;
                    border: none;                   
                    box-shadow: none;
                    height: 54px;                    
                   padding-right: 0;
               }
          }
          .sbHolder {
               background-color: #ededed;
               height: 54px;
               margin-bottom: 11px;
               .sbSelector {
                    padding: 19px 14px;
               }
               .sbToggle {
                    right: 11px;
                    top: 14px;
               }
               .sbOptions {
                    top: 52px !important;
                    z-index: 100;
               }
          }
          .checkbox-block {
               margin-bottom: 11px;
               outline: 1px solid #ededed;
               label, input[type="number"] {
                    float: left;
               }
               input[type="checkbox"] {
                    display: none;
                    &:checked + label span {
                         position: relative;
                         &:after {
                              content: '';
                              margin: 3px;
                              display: block;
                              width: 10px;
                              height: 10px;
                              border-radius: 2px;
                              background-color: #fb6a19;
                         }
                    }
               }
               label {
                    width: calc(80% - 25px);
                    margin: 0 11px 0 14px;
                    height: 54px;
                    line-height: 54px;
                    span {
                         vertical-align: middle;
                         content: '';
                         background: #ededed;
                         border: 2px solid darken(#ededed,20%);
                         border-radius: 3px;
                         display: inline-block;
                         width: 20px;
                         height: 20px;
                         margin-right: 11px;
                    }
               }
               input[type="number"] {
                    width: 20.00473%;
                    padding: 5px 2px 5px 14px;
                    font-size: 15px;
                    margin: 0;
                    background: #ededed;
                    height: 54px;
                    border: none;
                    box-shadow: none;
                    &::placeholder {
                         color: #545454;
                    }
               }
          }
          p {
               padding: 0 14px;
               margin-bottom: 11px;
               font-size: 13px;
          }
     }
}

.inner-wrapper {
    padding: 3em 0;
    text-align: center;
}

.property-wrapper {
    h2 {
        @include font-size(28);

        padding: .5em 0;
    }

    margin-bottom: 4em;
}

footer {
    color: #fff;
}

#login {
    input[type='text'], input[type='password'] {
        background: #ededed;
        color: #545454;
        text-transform: capitalize;
        border: none;
        font-size: 15px;
        box-shadow: none;
        height: 54px;
        padding: 5px 35px 5px 14px;
        margin: 0 0 11px;
    }

    input[type='submit'] {
        width: 100%;
        height: 54px;
        padding: 9px 10px 10px;
        background: $sale-color;
        text-transform: uppercase;
        margin: 0 0 11px;

        &:hover {
            background: $sale-hover-color;
        }
    }
}

.tabs-content {
    #panel1 {
        form {
            .sbToggle {
                background: $sale-color;
            }

            button {
                background: $sale-color;

                &:hover {
                    background: $sale-hover-color;
                }
            }

            a {
                &:hover {
                    color: $sale-hover-color;
                }
            }
        }
    }
}

.property-image {
    .status-btn {
        &.offer-btn {
            background-color: rgba(darken($sale-color, 15%), 0.8);
            bottom: 10px;
            top: auto;
        }
    }
}

/* Ajax form error styles */
#propertyForm {
    input[type="text"], input[type="tel"] {
        &.invalid {
            border: 1px solid lighten(red, 15%);
            background: lighten(red, 35%);
        }
    }
}

/* Brecht corrections */
/* ================== */
/* Home text blocks */
.about-content,
.why-choose-block {
    text-align: left;

    h3 {
        text-align: left;

        &:before {
            right: auto;
        }
    }
}

.why-choose-block {
    h3 {
        color: white;

        &:before {
            background: white;
        }
    }
}

.extras-block {
    float: left;
}

/* Thumbnails list */
.block-single {
    margin-bottom: 2em;
}

/* Properties list */
.featured-container {
    .large-12 {
        span {
            &.total-results {
                float: left;
                line-height: 2.4375rem;
            }
        }

        .selectbox-outer {
            width: 150px;
            float: right;
            margin-bottom: 2em;
        }
    }
}

header {
    .social-icons {
        a {
            color: #fff;
        }
    }
}

/* Media queries */
/*===============*/
@include breakpoint(medium only) {
    header {
        .social-icons {
            margin: 2px;
        }
    }

    .detail-page-slider {
        padding: 0 0 95px;
    }

    .detail-tab {
        margin-top: 2em;
    }
}


@include breakpoint(small only) {
    header {
        .phone-number {
            margin-bottom: .5em;
        }

        .logo {
            a {
                img {
                    width: 125px;
                    height: auto;
                }
            }
        }
    }

    .search-content {
        .single-field {
            &:nth-child(5) {
                float: left;
            }

            &.input {
                max-width: 100%;
                margin-right: 0px;
                margin-left: 7px;
                display: inline-block;
                float: left;
                width: 48%;
            }
        }
    }
}


@media screen and (max-width: 479px) {
    .search-content {
        .single-field {
            &.input {
                max-width: 100%;
                margin: 0px;
                display: inline-block;
                float: left;
                width: 100%;
            }
        }
    }
}

/* Property details fontawesome icons */
.details-block {
    .details-single {
        .detail-list {
            li {
                padding-left: 2em;
                position: relative;

                i {
                    width: 25px;
                    text-align: center;
                    color: #999;

                    &.fa {
                        @include font-size(18);

                        position: absolute;
                        left: 0em;
                    }
                }

                &:nth-child(7) {
                    & > label {
                        & > i {
                            width: 18px;
                        }
                    }
                }
            }
        }
    }
}

.detail-tab {
    .tabs-content {
        .landmark-distance {
            ul {
                li {
                    padding-left: 2em;
                    position: relative;

                    i {
                        width: 20px;
                        text-align: center;
                        color: #999;

                        &.fa {
                            @include font-size(18);

                            position: absolute;
                            left: 0em;
                            top: 1px;
                        }
                    }
                }
            }
        }
    }
}

.rate-block ul li .rate-left .date-period {
    width: 70%;
}

:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.descripcion {
    p {
        color: #383838;
    }

    ul {
        list-style: none;
        padding-left: 1.5rem;
        margin-bottom: 2rem;

        li {
            color: #383838;

            &:before {
                content: '\f111';
                font-family: 'fontawesome';
                font-size: 8px;
                padding-top: .65em;
                margin-left: -1.5em;
                float: left;
            }
        }
    }
}

#viviendas-alquiler {
    .wide-large {
        padding: 0;

        @include breakpoint(medium only) {
            padding: 0 .9375rem;
        }
    }

    .descripcion {
        ul {
            li {
                &:before {
                    color: $rental-color;
                }
            }
        }
    }
}

#viviendas-venta {
    .descripcion {
        ul {
            li {
                &:before {
                    color: $sale-color;
                }
            }
        }
    }
}

@include breakpoint(small only) {
    .landmark-distance {
        .fa {
            margin-right: .25em;

            #palm-tree, #golf-icon {
                fill: #404040;
                width: 14px;
                height: 14px;
            }
        }
    }
}


// Home about us section bottom space fix
.about-content {
    padding: 25px 0 35px 125px;
}

// Joined prices and estras
.rate-block {
    width: 55%;
    margin-right: 1%;
}

.extras-block, .block-content.options {
    width: 44%;
    padding: 0;
}

.rate-block, .extras-block, .block-content.options {
    float: left;

    @include breakpoint(small only) {
        width: 100%;
        margin-left: 0;
    }


    ul {
        margin-left: 0;
        margin-bottom: 2em;
    }
}

.block-content.options {
    ul {
        li {
            width: 100%;
            border-bottom: 1px solid #ededed;
            padding: 7px 22px 8px 10px;
            display: table;

            .extra-label {
                display: table-cell;
                vertical-align: middle;
                width: 70%;
                color: #404040;
                font-size: 14px;
                font-weight: 400;
            }

            .extra-amount {
                width: auto;
                color: #404040;
                font-size: 14px;
                font-weight: 700;
                display: table-cell;
                vertical-align: middle;
                text-align: right;
            }
        }
    }
}

// Search form bottom checkbox
.icheckbox-outer {
    li {
        width: 165px;

        @media only screen and (max-width: 479px) {
            display: inline-block;
            width: calc(100% - 5em);
            margin: .5em 2.5em;

            label {
                font-size: 14px;
            }
        }
    }
}

#car-rental {
    table {
        width: 100% !important;
    }
}

.ui-widget-header .ui-icon {
    background-image: url("../images/ui-icons_222222_256x240.png");
}

// Home creditcard logos styles
.why-choose-box {
     h4 {
          font-size: 20px;
          color: #fff;
          padding: 1.5em 0 .75em;
     }
     .creditcard-outer {
          width: 100%;

          @media screen and (min-width: 64em) and (max-width: 85.375em) {
               position: static;
               width: auto;
               margin-bottom: 24px;
          }

          .creditcard-logos {
               background-color: #fff;
               width: calc((100% - 24px) / 4);
               padding: 2px;
               height: auto;
               border-radius: 3px;
               margin: 0 8px 8px 0;
               float: left;
               height: 46px;
               &:last-of-type {
                    margin-right: 0;
               }
          }
     }
}

// Ref tag properties thumbnails
.property-image {
    .ref-tag {
        position: absolute;
        background-color: rgba(0, 93, 125, 0.7);
        top: 0px;
        font-size: 13px;
        font-weight: 600;
        padding: 4px 9px 5px 10px;
        color: #fff;
        -webkit-transition: all .6s ease-in-out;
        -moz-transition: all .6s ease-in-out;
        -ms-transition: all .6s ease-in-out;
        -o-transition: all .6s ease-in-out;
        transition: all .6s ease-in-out;
          i {
               font-size: 10px;
               display: inline-block;
               font-weight: 300;
               vertical-align: top;
               padding-top: 1px;
          }
        &.left-position {
            left: 0;
        }
    }

    a {
        &:hover {
            .ref-tag {
                opacity: 0;
            }
        }
    }
}

// Mobile menu dropdown fix 
@media screen and (max-width:61.9375em) {
     .offcan-outer {
          .main-menu {
               ul {
                    li {
                         text-align: left;
                         padding-left: 1em;
                         ul {
                              display: block;
                              li {
                                   padding-left: 1em;
                              }
                         }
                         &:hover {
                              ul {
                                   display: block;
                              }
                         }
                    }
               }
          }
     }
}

// Datepicker position fix
#ui-datepicker-div {
     z-index: 998 !important;
}

// Home block changes
#inicio {
     .why-choose-content {
          width: 40.17%;
    }
    .contact-info {
          width: 37.83%;
    }
    .why-choose-thumb {
         height: 100%;
         background-position: left center !important;
    }
}
