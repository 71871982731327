/*===============================================
    Footer Section
==================================================*/
.footer-container{
    float: left;
    width: 100%;
    background: $txt-col;
    font-family: $mainfont;
	padding: 26px 0 27px;
	position: relative;
}
.footer-single{
    float: left;
    width: 100%;
    font-size: $size-base;
	
	span{
		color: $primary-col;
	}
	
	 a{
		color: $primary-col;
		font-weight: $main-semibold;
	}
	
	&.phone-contact{
		width: 50%;
		padding-left: 39px;
		position: relative;
		
		&:before{
			position: absolute;
			left: 0;
			top: -3px;
			content: "\f095";
			font-family: 'FontAwesome';
			font-size: 25px;
			color: $primary-col;
		}
		
		a{
			color: $lighter;
			
			&:hover{
				color: $primary-col;
			}
		}
	}
	
	&.mail-contact{
		width: 50%;
		padding-left: 37px;
		position: relative;
		
		&:before{
			position: absolute;
			left: 0;
			top: 0;
			content: "\f0e0";
			font-family: 'FontAwesome';
			font-size: 19px;
			color: $primary-col;
		}
		
		a{			
			&:hover{
				color: $lighter;
			}
		}
	}
	
	a{
		font-weight: 400;
		
		&:hover{
			color: $lighter;
		}
		
		&.footer-link{
			color: $lighter;
			font-weight: 400;
			
			&:hover{
				color: $primary-col;
			}
		}
	}
} 
.site-logo{
	float: right;
    position: absolute;
    right: 0;
    top: -13px;
    width: auto;
	
	span{
		float: right;
		color: $site-text;
		text-transform: uppercase;
		font-size: $size-base;
		margin: 39px 9px 0 0;
	}
	
	a{
		float: right;
		
		img{
			float: left;
		}
	}
	img {
		padding-top: 15px;
	}
}











/*===============================================
    Media queries
==================================================*/

@include breakpoint(xlarge only) {
      
}
@media only screen and (max-width: 1400px) {//0-1400
    
}
@media only screen and (min-width: 992px) and (max-width: 1169px) {  
    .footer-single { font-size: 13px;}
    .site-logo span{display: none;}
	.footer-single.phone-contact{padding-left: 30px; width: 43%;}
	.footer-single.mail-contact{padding-left: 30px; width: 47%;}
 
} 
@media only screen and (min-width: 992px) {
	
	
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.footer-single{margin-top: 15px;}
	.footer-single.phone-contact{margin-top: 0;}
	.footer-single.mail-contact{margin-top: 0;}
	.footer-container{padding: 12px 0 20px;}
	.site-logo span{display: none;}
	.footer-container .medium-6{width: 100%;}
	.site-logo{top: auto;bottom: 0;}
	.site-logo span{display: block;}
}
@media only screen and (max-width: 767px) {
	.footer-container{padding: 11px 0 27px;}
  	.footer-single.phone-contact{padding-left: 39px;margin-top: 10px;width: 100%;font-size: 14px;}
  	.footer-single.mail-contact{padding-left: 39px;margin-top: 16px;width: 100%;font-size: 14px;}
	.footer-single{margin-top: 19px;font-size: 12px;width: 75%;}
	.site-logo span{display: none;}
	.site-logo{top: auto;bottom: 0;}
}
@media only screen and (max-width: 479px) {
	
}