html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
    //color: $lighter;
}

body{
    font-family: $mainfont;
    font-size: $size-base; 
	@include transition(all, .4s, ease-in-out);
	color:$tab-content;
}
html{
	@include transition(all, .4s, ease-in-out);
}

/**
 * 2.0 Headings
 * ----------------------------------------------------------------------------
 */

h1,
h2,
h3,
h4,
h5,
h6 {
	clear: both; 
	margin:0;
	padding: 0;
}

h1 {
    text-transform: capitalize;
	font-family: $mainfont;
	font-weight: 600;
	font-size: $size-h1;
	line-height:37px; 
	padding-bottom: 23px;
	position: relative;
	
	&:before{
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		content: "";
		width: 30px;
		height: 2px;
		background: $primary-col;
		margin: 0 auto;
	}
}

h2 {
    font-family: $mainfont;
	font-weight: 600;
	line-height:37px; 
}
h3 {
    font-family: $mainfont;
	font-weight: 600;
	font-size: $size-h3;
	line-height:37px; 
}
h4 {
    font-family: $mainfont;
	font-weight: 600;
	font-size: $size-h4;
	line-height:33px; 
}
h5 {
    font-family: $mainfont;
	font-weight: 600;
	font-size: $size-h5;
	line-height:33px; 
}
h6 {
    font-family: $mainfont;
	font-weight: 600;
	font-size: $size-h6;
	line-height:20px; 
}
p{ 
    font-size: $size-base;
	line-height:24px;
	color:$tab-content;
    margin: 0 0 24px 0;
}
h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
	margin-top: 0;
}


a {
    color:$txt-col;
    text-decoration: none;
	@include transit;
}
a:visited {
    outline: 0;
}

a:active,
a:hover,
a:focus {
    outline: 0;
    text-decoration: none;
	color: $primary-col;
    
}
strong{
	font-weight: 600;
}
ul li{
	list-style: none;
}
/* Placeholder text color -- selectors need to be separate to work. */

.mainfield::-webkit-input-placeholder {
	color: $tab-content;
    opacity: 1;
}
.mainfield:-moz-placeholder {
	color: $tab-content;
    opacity: 1;
}
.mainfield::-moz-placeholder {
	color: $tab-content;
    opacity: 1;
}
.mainfield:-ms-input-placeholder {
	color: $tab-content;
    opacity: 1;
}
input:disabled, input[readonly], textarea:disabled, textarea[readonly]{
	background: $input-bg;
}

 
input[type=text] {   
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
